@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

$sender-border:#F7971E;
$sender-bg:#FFFBE9;
$receiver-border:#FF512F;
$receiver-bg: #FFFEF9;
$gray: #92959E;

.threaded-sms-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 55px);
  background: #F2F5F8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #434651;
  
  .threaded-sms-header-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 auto;
    img {
      float: left;
    }
    
    .threaded-sms-close-icon{
        height: 20px;
        width: 20px;
    }

    .chat-about {
      float: left;
      margin-top: 6px;
      width: 90%;
    }
    
    .chat-with {
      font-weight: bold;
      font-size: 16px;
    }
    
    .chat-num-messages {
      color: $gray;
    }
    
  }
  
  .threaded-sms-chat-history {
    padding: 30px 30px 20px;
    border-bottom: 2px solid white;
    overflow-y: scroll;
    flex: 1 1 auto;
    
   .left-side, .clearfix{
      list-style: none;
    }

    .message-date {
        margin: 0 auto;
        text-align: center;
  
        span {
          padding: 3px 5px;
          font-size: 12px;
          border-radius: 5px;
          background: #fdd5b4;
        }
    }

    .message-data {
      margin-bottom: 15px;
    }
    
    .message-data-time {
      color: lighten($gray, 8%);
      padding-left: 6px;
      font-size: 14px;
    }
    
    .message {      
      color: white;
      padding: 18px 20px;
      line-height: 26px;
      font-size: 16px;
      border-radius: 7px;
      margin-bottom: 30px;
      width: 50%;
      position: relative;
      
      &:after {
        bottom: 100%;
        left: 7%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $receiver-border;
        border-width: 10px;
        margin-left: -10px;
      }
    }
    
    .my-message {
      background: $receiver-bg;
      border: 1px solid;
      border-color: $receiver-border;
      color: #000000;
    }
    
    .other-message {
      background: $sender-bg;
      border: 1px solid;
      border-color: $sender-border;
      color: #000000;
      overflow-wrap: break-word;
      
      &:after {
        border-bottom-color: $sender-border;
        left: 93%;
      }
    }
    
  }
  
  .chat-message {
    padding: 30px;
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    
    textarea {
      width: 95%;
      border: none;
      padding: 10px 20px;
      font: 14px/22px "Lato", Arial, sans-serif;
      margin-bottom: 10px;
      border-radius: 5px;
      resize: none;
      
    }
    
  }
  
  .align-right {
    text-align: right;
  }
  
  .float-right {
    float: right;
  }
  
  .clearfix:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
  }

  .loading-spinner{
    justify-content: center;
    text-align: center;
  }
}