@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import "./vendor/bootstrap/bootstrap.scss";
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

$gray: #92959E;
$orange: #FF4D00;
$red: #FF0000;

@font-face {
  font-family: "Conv_AvenirNextLTPro-Bold";
  src: url("./fonts/AvenirNextLTPro-Bold.eot");
  src: url("./fonts/AvenirNextLTPro-Bold.woff") format("woff"),
    url("./fonts/AvenirNextLTPro-Bold.ttf") format("truetype"),
    url("./fonts/AvenirNextLTPro-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/AvenirNextLTPro-Regular.eot");
  src: local("☺"), url("./fonts/AvenirNextLTPro-Regular.woff") format("woff"),
    url("./fonts/AvenirNextLTPro-Regular.ttf") format("truetype"),
    url("./fonts/AvenirNextLTPro-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Conv_AvenirNextLTPro-It";
  src: url("./fonts/AvenirNextLTPro-It.eot");
  src: local("☺"), url("./fonts/AvenirNextLTPro-It.woff") format("woff"),
    url("./fonts/AvenirNextLTPro-It.ttf") format("truetype"),
    url("./fonts/AvenirNextLTPro-It.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/** Generated by FG **/
@font-face {
  font-family: "Conv_AvenirNextLTPro-Demi";
  src: url("./fonts/AvenirNextLTPro-Demi.eot");
  src: local("☺"), url("./fonts/AvenirNextLTPro-Demi.woff") format("woff"),
    url("./fonts/AvenirNextLTPro-Demi.ttf") format("truetype"),
    url("./fonts/AvenirNextLTPro-Demi.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

// @import"~slick-carousel/slick/ajax-loader.gif";
$hoge-font-size: 2em;

* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Roboto";
}

.container-fluid {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
}

.types-divider {
  height: 1px;
  background-color: lightgrey;
}

.descriptive-textbox {
  max-width: 100%;
}

.badge-cmod {
  display: inline-block;
  border: 2px solid orange;
  border-radius: 10px;
  color: orange;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 50px;
  min-height: 20px;
  padding: 2px;
  line-height: normal;
  text-align: center;
}

@media screen and (max-width: 425px) {


  .img-message {
    width: 100%;
    max-height: 175px;
  }

  .login-container {
    width: 88%;

    input {
      border: 2px solid #fff;
      font-family: "Conv_AvenirNextLTPro-It";
      font-size: 12px;
      color: $gray-100;

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
        border-left: 2px solid rgba(98, 116, 230, 0.8);
      }
    }

    select {
      border: 2px solid #fff;
      font-family: "Conv_AvenirNextLTPro-It";
      font-size: 12px;
      color: $gray-100;

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
        border-left: 2px solid rgba(98, 116, 230, 0.8);
      }
    }

    .form-group {
      span {
        float: right;
        left: -15px;
        margin-top: -30px;
        position: relative;
        z-index: 2;
        font-size: 12px;
        color: $gray-100;
      }
    }

    .sign-up {
      float: right;

    }

    p {
      font-size: 12px;
    }

    .col-sm-7 {
      position: relative;
    }
  }

  .user-profile-sidebar-2 {
    position: fixed;
    height: 100vh;
    width: 100%;
    background: #ffffff;
    box-shadow: $boxshadow;
    right: 0;
    z-index: 4;
    transition: all 0.2s ease-in-out;

    .header-section {
      background: #ffffff;
      box-shadow: 0 7px 15px 0 rgba(165, 165, 165, 0.2);
      position: relative;
      padding-top: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid #9da3b3;
      display: flex;
      justify-content: space-between;
      padding-left: 60px;
      padding-right: 60px;

      h1 {
        margin: 0;
        word-break: break-word;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
      }
    }
  }

  .left-sidebar {
    width: 100%;
    background: #ffffff;
    box-shadow: none;
    position: relative;
    z-index: 9;
    overflow-y: scroll;
    height: calc(100vh - 90px);
  }

  .form-response {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    border: 1px solid #9da3b3;
    margin-top: 20px;
  }

  #display-data {
    margin-bottom: 30px;

    // background-color: lightblue;
    .badge {
      width: 25px;
      height: 25px;
    }

    .chatText {
      margin-left: 35px;
      position: relative;
      top: -5px;
      max-width: 90%;
      align-items: flex-end;
    }

    .msgcontainer {
      margin-right: 10px;
    }
  }

  #display-data-Container {
    height: calc(100vh - 350px);
    overflow-y: scroll;

    .message-date {
      margin: 0 auto;
      text-align: center;

      span {
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 5px;
        background: #fdd5b4;
      }
    }

    .user-badge {
      display: inline-block;
      margin-right: 5px;
    }

    .username {
      cursor: pointer;
    }

    #reply-message-container {
      padding-top: 5px !important;
      margin-left: 15px;
    }
  }

  #display-feed-data-Container {
    position: relative;
    height: 100%;

    .message-date {
      margin: 0 auto;
      text-align: center;

      span {
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 5px;
        background: #fdd5b4;
      }
    }

    .user-badge {
      display: inline-block;
      margin-right: 5px;
    }

    .username {
      cursor: pointer;
    }
  }

  .right-section {
    flex: 1;
    position: relative;

    .header-section {
      background: #ffffff;
      box-shadow: 0 7px 15px 0 rgba(165, 165, 165, 0.2);
      position: relative;
      padding-top: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid #9da3b3;
      padding-right: 60px;
      padding-left: 60px;
      display: flex;
      justify-content: space-between;

      .assign-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left-assign-container {
          display: flex;
          flex-direction: "row";

          .assign-text {
            margin: 0;
            word-break: break-word;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 33px;
            padding-right: 15px;
          }

          .assigned-admins {
            display: flex;
            flex-wrap: wrap;
            align-items: "center";

            .selected-admins {
              padding-left: 15px;
              padding-right: 15px;
              padding-top: 5px;
              padding-bottom: 5px;
              margin: 5px;
              background-color: #01ceb5;
              color: white;
              border-radius: 20px;
            }
          }
        }

        .assign-img {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding-right: 30px;
          padding-left: 50px;
          border-left: 1px solid gray;
        }
      }
    }

    .assign-padding {
      padding-left: 36px;
    }

    .center {
      text-align: center;
    }

    .sitename-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sitename {
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }

    .empty-container {
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .input-container {
      position: fixed;
      width: 100%;
      right: 0px;
      bottom: 0px;
      height: auto;
      background: #fff;
      border-top: 1px solid $border-color;
      z-index: 2;
      margin: 1px;

      .ant-dropdown-trigger {
        position: absolute;
        right: 75px;
        top: 30px;
      }

      .anticon-bg-colors {
        font-size: 26px;
        color: #333;
      }

      textarea {
        width: calc(100%);
        padding: 10px;
        border: none;
        color: $black;
        font-size: 16px;
        padding-right: 125px !important;
        text-align: justify;
        resize: none;

        &:focus {
          outline: none !important;
          box-shadow: none;
        }
      }

      .send-form {
        position: absolute;
        right: 155px;
        cursor: pointer;
        top: 30px;
        height: 25px;
        width: 25px;
        color: #000;
      }

      .send-img-img {
        position: absolute;
        cursor: pointer;
        right: 115px;
        top: 30px;
        height: 25px;
        width: 25px;
      }

      .send-text-img {
        position: absolute;
        right: 30px;
        top: 30px;
      }

      .expand-textarea-img {
        position: absolute;
        top: 30px;

        &.waiting-section {
          right: 120px;
        }

        &.view-in-context {
          right: 75px;
        }
      }
    }

    .msgcontainer {
      background: $btn-default;
      min-width: 15rem;
      max-width: 600px;
      word-break: break-word;
      padding: 5px;
      border-radius: 4px;
      display: inline-flex;
      min-height: 45px;

      pre {
        margin-bottom: 0px;
        white-space: pre-wrap;
        /* css-3 */
        white-space: -moz-pre-wrap;
        /* Mozilla, since 1999 */
        white-space: -pre-wrap;
        /* Opera 4-6 */
        white-space: -o-pre-wrap;
        /* Opera 7 */
        word-wrap: break-word;
      }

      &.delete {
        position: relative;
        min-width: calc(20rem - 10px);
        max-width: calc(90% - 10px);
        margin-right: 10px;

        &:after {
          content: "";
          position: absolute;
          right: -8px;
          top: 0;
          bottom: 0;
          background: red;
          width: 10px;
          border-radius: 0px 4px 4px 0px;
          z-index: -9;
        }
      }

      .ticks {
        &.seen {
          color: $primary;
        }

        float: right;
      }
    }
  }

  .hide-content {
    display: none;
  }

  .select-group-message {
    display: none;
  }


  .my-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;

    .my-nav-btn {
      color: #FB9048;
      border: 1px solid #FB9048;
      background: #fff;
      border-radius: 10px;
      padding: 4px 6px;
      text-align: center;
      font-weight: 600;
      outline: none;
    }

    .my-nav-btn:disabled {
      color: #ccc;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 10px;
      padding: 4px 6px;
      text-align: center;
      font-weight: 600;
      outline: none;
    }

    .right {
      position: absolute;
      right: 10px;
    }

    .left {
      position: absolute;
      left: 15px;
    }
  }
}

@media screen and (min-width: 426px) {


  .img-message {
    width: 100%;
    max-height: 50%;
  }


  .navbar-nav {
    li {
      font-family: "Conv_AvenirNextLTPro-Bold";

      .alert-container-active {
        border-bottom-color: #e64821;
        border-bottom-width: 3px;
        border-bottom-style: solid;
      }

      .alert-container {
        min-width: 130px;
        justify-content: center;
        display: flex;
        position: relative;
        margin-left: 18px;
        font-size: 18px;

        .alert-count {
          min-width: 1.6em;
          height: 1.6em;
          margin: 7px 0 0 5px;
          background: red;
          color: white;
          display: flex;
          font-size: 0.667em;
          padding: 0.2em 0.2em 0 0.2em;
          justify-content: center;
          border-radius: 0.8em;
          align-items: center;
          border: 0.05em solid white;
        }

        .nav-item {
          margin-left: 0;
        }
      }
    }
  }

  .login-container {
    max-width: 550px;
    width: 100%;

    input {
      border: 2px solid #fff;
      font-family: "Conv_AvenirNextLTPro-It";
      font-size: 12px;
      color: $gray-100;

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
        border-left: 2px solid rgba(98, 116, 230, 0.8);
      }
    }

    select {
      border: 2px solid #fff;
      font-family: "Conv_AvenirNextLTPro-It";
      font-size: 12px;
      color: $gray-100;

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
        border-left: 2px solid rgba(98, 116, 230, 0.8);
      }
    }

    .form-group {
      span {
        float: right;
        left: -15px;
        margin-top: -30px;
        position: relative;
        z-index: 2;
        font-size: 12px;
        color: $gray-100;
      }
    }

    .sign-up {
      float: right;
    }

    p {
      font-size: 12px;
    }

    .col-sm-7 {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -15px;
        border: 1px solid $border-color;
      }
    }
  }

  .display-flex {
    display: flex;
  }

  .user-profile-sidebar-2 {
    position: fixed;
    height: 100vh;
    width: 72%;
    background: #ffffff;
    box-shadow: $boxshadow;
    right: 0;
    z-index: 4;
    transition: all 0.2s ease-in-out;

    .header-section {
      background: #ffffff;
      box-shadow: 0 7px 15px 0 rgba(165, 165, 165, 0.2);
      position: relative;
      padding-top: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid #9da3b3;
      display: flex;
      justify-content: space-between;
      padding-left: 60px;
      padding-right: 60px;

      h1 {
        margin: 0;
        word-break: break-word;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
      }
    }
  }

  .form-response {
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    margin-right: 48px;
    border: 1px solid #9da3b3;
    margin-top: 20px;
  }

  .my-navbar {
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    padding: 1.625rem 4rem 1.625rem 2rem !important;
    justify-content: space-between;

    .my-nav-btn {
      display: none;
    }

  }

  .left-sidebar,
  .right-sidebar {
    height: calc(100vh - 90px);
    width: 28%;
    background: #ffffff;
    box-shadow: none;
    position: relative;
    z-index: 9;
    overflow-y: scroll;
  }

  .select-group-message {
    margin: auto;
  }

  #display-data {
    margin-bottom: 30px;

    // background-color: lightblue;
    .badge {
      width: 25px;
      height: 25px;
    }

    .chatText {
      margin-left: 35px;
      position: relative;
      top: -5px;
      max-width: 70%;
      align-items: flex-end;
    }



    .msgcontainer {
      margin-right: 10px;
    }
  }

  #display-data-Container {
    height: calc(100vh - 260px);
    overflow-y: scroll;

    .message-date {
      margin: 0 auto;
      text-align: center;

      span {
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 5px;
        background: #fdd5b4;
      }
    }

    .user-badge {
      display: inline-block;
      margin-right: 5px;
    }

    .username {
      cursor: pointer;
    }

    #reply-message-container {
      padding-top: 5px !important;
      margin-left: 15px;
    }
  }

  #display-feed-data-Container {
    position: relative;
    height: 100%;

    .message-date {
      margin: 0 auto;
      text-align: center;

      span {
        padding: 3px 5px;
        font-size: 12px;
        border-radius: 5px;
        background: #fdd5b4;
      }
    }

    .user-badge {
      display: inline-block;
      margin-right: 5px;
    }

    .username {
      cursor: pointer;
    }
  }

  .right-section {
    flex: 1;
    position: relative;

    .header-section {
      background: #ffffff;
      box-shadow: 0 7px 15px 0 rgba(165, 165, 165, 0.2);
      position: relative;
      padding-top: 25px;
      padding-bottom: 15px;
      border-bottom: 1px solid #9da3b3;
      padding-right: 60px;
      padding-left: 60px;
      display: flex;
      justify-content: space-between;

      .assign-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left-assign-container {
          display: flex;
          flex-direction: "row";

          .assign-text {
            margin: 0;
            word-break: break-word;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 33px;
            padding-right: 15px;
          }

          .assigned-admins {
            display: flex;
            flex-wrap: wrap;
            align-items: "center";

            .selected-admins {
              padding-left: 15px;
              padding-right: 15px;
              padding-top: 5px;
              padding-bottom: 5px;
              margin: 5px;
              background-color: #01ceb5;
              color: white;
              border-radius: 20px;
            }
          }
        }

        .assign-img {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding-right: 30px;
          padding-left: 50px;
          border-left: 1px solid gray;
        }
      }
    }

    .assign-padding {
      padding-left: 36px;
    }

    .center {
      text-align: center;
    }

    .sitename-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sitename {
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }

    .empty-container {
      padding-bottom: 15px;
      padding-top: 15px;
    }

    .input-container {
      position: fixed;
      left: 28%;
      right: 0px;
      bottom: 0px;
      height: auto;
      background: #fff;
      border-top: 1px solid $border-color;
      z-index: 2;
      margin: 1px;

      .anticon-bg-colors {
        font-size: 26px;
        color: #333;
      }

      textarea {
        width: calc(100%);
        border-left: none !important;
        border-right: none;
        border-bottom: none;
        margin: 15px;
        border: none;
        color: $black;
        font-size: 16px;
        padding-right: 235px !important;
        text-align: justify;
        resize: none;

        &:focus {
          outline: none !important;
          box-shadow: none;
        }
      }

      .send-form {
        cursor: pointer;
        height: 25px;
        width: 25px;
        color: #000;

        &.active {
          position: absolute;
          right: 75px;
          top: 30px;
        }
      }

      .send-img-img {
        cursor: pointer;
        height: 25px;
        width: 25px;

        &.active {
          position: absolute;
          right: 75px;
          top: 30px;
        }
      }

      .send-gif-img {
        cursor: pointer;
        height: 25px;
        width: 25px;
      }

      .send-text-img {
        position: absolute;
        right: 30px;
        top: 30px;
      }

      .expand-textarea-img {
        position: absolute;
        top: 30px;

        &.waiting-section {
          right: 120px;
        }

        &.view-in-context {
          right: 75px;
        }
      }
    }

    .msgcontainer {
      background: $btn-default;
      min-width: 20rem;
      max-width: 600px;
      word-break: break-word;
      padding: 5px;
      border-radius: 4px;
      display: inline-flex;
      min-height: 45px;

      pre {
        margin-bottom: 0px;
        white-space: pre-wrap;
        /* css-3 */
        white-space: -moz-pre-wrap;
        /* Mozilla, since 1999 */
        white-space: -pre-wrap;
        /* Opera 4-6 */
        white-space: -o-pre-wrap;
        /* Opera 7 */
        word-wrap: break-word;
      }

      &.delete {
        position: relative;
        min-width: calc(20rem - 10px);
        max-width: calc(90% - 10px);
        margin-right: 10px;

        &:after {
          content: "";
          position: absolute;
          right: -8px;
          top: 0;
          bottom: 0;
          background: red;
          width: 10px;
          border-radius: 0px 4px 4px 0px;
          z-index: -9;
        }
      }

      .ticks {
        &.seen {
          color: $primary;
        }

        float: right;
      }
    }
  }
}

.nav-item-text {
  font-family: "Roboto" !important;
  font-size: 20px !important;
  color: #0b0b0b;
}

.nav-item {
  @extend  .nav-item-text;
  margin: 2px 0 2px 30px;
  color: #BABABA;
}

.nav-item-select {
  @extend  .nav-item-text;
  margin-left: 24px;
}

.nav-item-active {
  /* font-family: "Conv_AvenirNextLTPro-Bold" !important; */
  color: #F98654;

  svg {
    path {
      fill: #F98654;
    }
  }
}

.nav-menu-item {
  @extend  .nav-item-text;
  text-align: center;
}

.confirm-backdrop {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.78);
  height: 100vh;
  z-index: 10;
}

.event-container {
  overflow-y: scroll;
  height: calc(100vh - 25vh);
  margin: 0 0 0 36px;
  padding-right: 10px;

  .group-name {
    font-size: 20px;
    color: #000;
    font-weight: 500;
  }
}

.event {
  padding: 20px;
  margin-top: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  ::placeholder {
    color: #C7C7CD
  }

  .new-event-input {
    outline: none;
    padding: 5px;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    outline: none;
    box-shadow: none;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .new-event-table-input {
    outline: none;
    padding: 5px;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    outline: none;
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .date-input {
    outline: none;
    padding: 5px;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    outline: none;
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .link {
    color: #1890ff;
  }



  .no-event-members {
    text-align: center;
    margin: 15px;
    font-size: 15px;
  }

  .expand {
    justify-content: center;
    align-items: center;
    display: flex;

    .expand-img {
      height: 16px;
      width: 16px;
      cursor: pointer;
    }

  }

  .event-top {
    .event-heading {
      display: flex;
      justify-content: space-between;
    }

    .disp-flex {
      display: flex;
      padding: 4px 0;
    }
  }

  .edit-event {
    position: absolute;
    right: 12px;
  }

  .event-bottom {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .count {
      font-size: 17px;
      color: #000;
      font-weight: 600;
      display: flex;

      .count-right {
        margin-left: 15px;
      }
    }

  }

  .label {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    width: 24%;
  }

  .label-value {
    font-size: 15px;
    font-weight: 500;
    width: 100%;
  }

  .group-name {
    font-size: 20px;
    color: #000;
    font-weight: 500;
  }

  .to {
    font-size: 12px;
    color: #000;

  }

  .event-edit-button {
    display: flex;
    justify-content: space-between;

    .confirm {
      width: 100px;
      margin-bottom: 10px;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      background: #f79400;
      outline: none;
      border: 1px solid #f79400;
      padding: 5px 10px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .exit {
      background: none;
      border: none;
      color: #f79400;
      margin-left: 10px;
    }

    .delete {
      color: red;
      font-weight: 600;
      font-size: 16px;
      border: 0;
      background: #fff;
      outline: none;
    }
  }
}

.zoom-info-label {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-left: 4px;
}

.zoom-link {
  color: #1890ff;
  outline: none;
  border: 0;
}

.updating-event {
  position: absolute;
  left: 50%;
  top: 40%;
  z-index: 1;
}


.new-event-input-container {
  padding: 25px 32px;
  overflow-y: scroll;
  height: calc(100vh - 250px);


  .new-event-label {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    line-height: 14px;
    padding: 4px;
    margin-bottom: 2px;
  }


  .new-event-input {
    outline: none;
    padding: 5px;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    outline: none;
    box-shadow: none;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .date-input {
    outline: none;
    padding: 5px;

    font-size: 15px;
    font-weight: 600;
    color: #000;
    outline: none;
    box-shadow: none;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

}

.eligibe-site-container {
  overflow-y: scroll;
  height: 275px;
}


.new-referral-modal {
  position: fixed;
  max-height: 80vh;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  z-index: 2;
  display: flex;
  left: 27%;
  top: 20%;
  width: 45%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  .new-refer-desc {
    margin-bottom: 10px;
  }

  .send-refer {
    text-align: center;
  }


  .new-refer-input-container {
    width: 60%;

    .new-referral-label {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: #000;
      line-height: 14px;
      padding: 4px;
      margin-bottom: 2px;
    }

    ::placeholder {
      color: #ccc;
    }

    .new-referral-input {
      outline: none;
      padding: 5px;
      width: 100%;
      font-size: 15px;
      font-weight: 600;
      color: #000;
      outline: none;
      box-shadow: none;
      margin-bottom: 15px;
      border-radius: 10px;
      border: 1px solid #ccc;
    }
  }

  .close-new-referral {
    cursor: pointer;
    position: absolute;
    right: 20px;
    height: 20px;
    width: 20px;
  }

  .confirm {
    width: 100px;
    margin-bottom: 10px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    background: #f79400;
    outline: none;
    border: 1px solid #f79400;
    padding: 5px 10px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}

.saving-form {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 48%;
}

.error-modal-links {
  display: flex;
  justify-content: space-between;


  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #000;
    line-height: 14px;
    padding: 4px;
    margin: 4px 0;
  }
}

.confirm-modal {
  position: fixed;
  max-height: 80vh;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  z-index: 12;
  display: flex;
  left: 35%;
  top: 35%;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  h1 {
    justify-content: space-between;

    img {
      padding-left: 20px;
      cursor: pointer;
    }
  }

  p {
    margin-bottom: 15px;
    color: #000;
    font-size: 14px;
    text-align: center;
  }

  .modal-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .confirm {
      width: 100px;
      margin-bottom: 10px;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      background: #f79400;
      outline: none;
      border: 1px solid #f79400;
      padding: 5px 10px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .cancel {
      background: none;
      border: none;
      color: #f79400;
    }
  }
}

.no-chrome-modal {
  position: fixed;
  max-height: 80vh;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  z-index: 9;
  display: flex;
  left: 20%;
  top: 35%;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  .modal-heading {
    display: flex;
    justify-content: space-between;

    img {
      height: 22px;
      width: 22px;
      cursor: pointer;
    }
  }

  h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 15px;
    color: #000;
    font-size: 14px;
    text-align: center;
  }

  .modal-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .confirm {
      width: 100px;
      margin-bottom: 10px;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      background: #f79400;
      outline: none;
      border: 1px solid #f79400;
      padding: 5px 10px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .cancel {
      background: none;
      border: none;
      color: #f79400;
    }
  }
}

.events-modal {
  position: fixed;
  max-height: 80vh;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  z-index: 9;
  display: flex;
  left: 38%;
  top: 37%;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

  .modal-heading {
    display: flex;
    justify-content: space-between;

    img {
      height: 22px;
      width: 22px;
      cursor: pointer;
    }
  }

  h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;

  }

  p {
    margin-bottom: 15px;
    color: #000;
    font-size: 14px;
    text-align: center;

  }

  .modal-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .confirm {
      width: 100px;
      margin-bottom: 10px;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      background: #f79400;
      outline: none;
      border: 1px solid #f79400;
      padding: 5px 10px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .cancel {
      background: none;
      border: none;
      color: #f79400;
    }
  }
}

.cbrs-discontinue-modal {
  position: fixed;
  max-height: 80vh;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  z-index: 9;
  display: flex;
  left: 10%;
  top: 30%;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  height: 250px;

  .modal-heading {
    display: flex;
    justify-content: space-between;

    img {
      height: 22px;
      width: 22px;
      cursor: pointer;
    }
  }

  h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;

  }

  p {
    margin-bottom: 15px;
    color: #000;
    font-size: 14px;
    text-align: center;

  }

  .modal-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .confirm {
      width: 100px;
      margin-bottom: 10px;
      box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      background: #f79400;
      outline: none;
      border: 1px solid #f79400;
      padding: 5px 10px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }

    .cancel {
      background: none;
      border: none;
      color: #f79400;
    }
  }
}

.graph-container {
  padding: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 5px;
}

.rsn-questions {
  margin: 10px 0 5px;
}

.contact-dropdown {
  font-family: "Conv_AvenirNextLTPro-It";
  font-size: 12px;
  display: block;
  padding: 0 1.2rem 0.375rem 1.2rem;
  font-weight: 400;
}

.descriptive-textbox {
  outline: none;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin: 0 10px;
  background: #ffffff;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  padding: 6px;
  border: none;
  margin: 0;
  width: 100%;

  &.inactive {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #f79400;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1401);
  border-radius: 2px;
  border: 1px solid #f79400;
}

.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #f79400;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: " ";
}

.internet-error {
  position: relative;
  z-index: 1000;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  top: 0;
  width: 100%;
  padding: 1rem;
  background: #f6871e;
  justify-content: center;
  align-items: center;
  display: flex;
}

.iceBreaker-container {
  height: 400px;
  overflow-y: scroll;

  ::placeholder {
    color: darkgray;
    font-weight: 400;
  }

  .label-container {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;

    .character-count-label {
      color: $orange;
      text-align: right;
      margin-right: 20px;
    }
  }

  .iceBreaker-screen-label {
    font-size: 16px;
    color: black;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .iceBreaker-label {
    font-size: 16px;
    color: black;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .switch {
    margin-left: 20px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .iceBreaker-input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid darkgrey;
    padding: 5px;
    font-size: 15px;
    font-weight: 700;
  }
}

.empty-CBRS {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  color: #000;
  line-height: 25px;
  font-weight: 700;
  width: 100%;
  font-size: 17px;
}

.new-referral-button,
.new-referral-button:active,
.new-referral-button:after {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background: #f79400;
  outline: none;
  border: 1px solid #f79400;
  padding: 5px 10px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.new-referral-button-secondary {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background: #fff;
  outline: none;
  border: 1px solid #f79400;
  padding: 5px 10px;
  color: #f79400;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-right: 20px;
}

.show-export-history {
  font-family: Roboto;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #fb9048;
}

.new-referral-button:disabled {
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background: #ccc;
  outline: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.start-new-intake {
  padding: 16px 32px;

  .form-header {
    font-family: Roboto;
    font-size: 18px;
    line-height: 25px;
    color: #2a2a2a;
    font-weight: 500;
  }

}

.referral-scroll {
  overflow-y: scroll;
  height: calc(100vh - 160px);
}

.referral-header {
  background: #f2f2f2;
  position: relative;
  padding-top: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
  color: #000;
  font-weight: 700;

  .referral-time {
    font-size: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    color: #979797;
  }
}

.text-left {
  text-align: left;
}

.referral-section {
  padding: 25px 50px 10px 30px;
}

.self-intake-form {
  overflow-y: scroll;
  height: calc(100vh - 450px);
}

.forms-container {
  overflow-y: scroll;
  height: calc(100vh - 160px);
  background: #f8fbff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 12px;
}

.rsn-form-container {
  background: #f8fbff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 12px;
}

.diagnosis-textarea {
  outline: none;
  border: none;
}

.explaination-textArea {
  border: none;
  border-bottom: 1px solid #000;
}

.export-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.export-pdf-container {
  display: flex;
}

.referral-form-button {
  border-radius: 10px;
  background: #fff;
  outline: none;
  border: 1px solid #f79400;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  height: 34px;
  line-height: 22px;
  color: #f77700;
  margin-right: 8px;

  a {
    color: #f77700;
  }

  a:hover {
    color: #f77700;
  }

  a:active {
    color: #f77700;
  }
}

.referral-form-button:disabled {
  border-radius: 10px;
  background: #ccc;
  outline: none;
  border: 1px solid #ccc;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  margin-right: 8px;
}

.explanation {
  display: flex;

  .explain {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
  }
}

.form-progress {
  display: flex;
  padding: 10px 0;
}

.user-form-progress-bar {
  display: flex;
  width: 40%;
  margin: 0 20px;
  flex-direction: column;

  .form-progress-title {
    font-size: 13px;
    font-weight: 900;
    color: #2a2a2a;
  }
}

.form-progress-bar {
  display: flex;
  width: 200px;
  margin: 0 20px;
}

.ant-progress-inner {
  border: 1px solid #fb9048;
}

.referral-forms-container {
  background: #f8fbff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 12px;

  .form-header {
    font-family: Roboto;
    font-size: 20px;
    line-height: 25px;
    color: #2a2a2a;
    font-weight: 550;
  }
}

.provider-field {
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.lower-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.refferal-head {
  color: #000;
  font-weight: 900;
  text-align: left;
  font-size: 22px;
  margin-right: 20px;
}

.providers {
  width: 350px;

  .provider-info {
    display: flex;
    justify-content: space-between;
  }
}

.client-info-form {
  .field-container {
    margin: 10px 0;
  }

  input {
    outline: none;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    margin: 0 10px;
    background: #ffffff;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    padding: 6px;
    border: none;
    margin: 0;
    width: 80%;
  }

  ::placeholder {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 12px;
    color: #979797;
  }
}

.status-container {
  position: absolute;
  overflow-y: scroll;
  height: 140px;
}

.referral-status {
  margin: 12px 0 8px;
  display: flex;

  .refer-status {
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }

  .assign-status {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin-left: 20px;
  }
}

.referral-sub {
  color: #000;
  font-weight: 400;
  text-align: left;
  font-size: 16px;
  background: #f2f2f2;
  padding: 5px 32px 15px;
  align-items: center;

  .sub {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
  }

  .main {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #000;
  }
}

.refer-input-container {
  width: 60%;
}

.refer-textbox {
  border-radius: 15px;
  padding: 10px;
  outline: none;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.19);
  position: relative;
  width: 100%;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.start-referral {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 15px;

  .bold {
    font-weight: bold;
  }
}

.update-status {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 15px;

  .bold {
    font-weight: bold;
  }
}

.refer-message {
  background: #ffffff;
  opacity: 0.46;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  font-family: Roboto;
  font-style: italic;
  width: 455px;
  padding: 10px;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.76);
}

.referral-input {
  outline: none;
  padding: 5px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.16);
  border: 1px solid #ccc;

  &.message-input {
    height: 220px;
  }
}

.referral-checkbox {
  outline: none;
  padding: 5px;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #000;
  outline: none;
  margin-bottom: 15px;
  border-radius: 5px;
}

.referral-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 20%;
}

.invite-sms {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  line-height: 14px;
  padding: 4px;
  margin: 4px 0;
}

.choose-form {
  margin: 10px 0
}

.send-referral {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.referral-box {
  display: flex;
  justify-content: center;


}

.send-new-referral {
  padding: 0 32px;
}

.new-referral-header {
  background: #f2f2f2;
  position: relative;
  padding-top: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  align-items: center;
  font-size: 20px;
  line-height: 20px;
  color: #000;
  font-weight: bold;

  .new-referral-sub {
    font-size: 14px;
    padding: 4px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 16px;
    color: #979797;
  }
}

.my-radio {
  width: 100px;
}

.anticon-more {
  transform: rotate(90deg);
  margin-left: 10px;
  font-size: 22px;
  position: relative;
  top: 10px;
}

.my-btn.ant-btn {
  font-size: 16px;
  font-weight: 700;
}

.start-session,
.resolve-dm {
  font-size: 18px;
  font-weight: 600;
  margin-right: 10%;
  cursor: pointer;
}

.my-tabs-nav.ant-tabs-nav {
  width: 240px;
}

.online {
  width: 15px;
  height: 15px;
  background-color: greenyellow;
  border-radius: 8px;
  margin-right: 5px;
}

.congrats {
  font-size: 16px;
  color: #000;
  padding-bottom: 15px;
}

.download-text {
  font-size: 13px;
  color: #000;
  padding-bottom: 5px;
  padding-top: 5px;
}

.download-link,
.download-link:hover,
.download-link:active {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #FB9048;
}

header {
  background: #fff;
  z-index: 999;
  position: relative;
}

.logo {
  max-width: 415px;
  width: 100%;
  margin-top: 40px;

  small {
    color: $orange;
    margin-left: 5px;
    font-size: 9px;
    position: relative;
    top: 13px;
    font-family: "Roboto", sans-serif;
  }
}

.no-messages {
  font-size: 20px;
  color: #2a2a2a;
  text-align: center;
  position: relative;
  top: 175px;
  line-height: 26px;
}

.no-user-flags {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  position: relative;
  top: 170px;
  color: #2a2a2a;
}

.user-flair {
  font-size: 18px;
  margin-left: 5px;
}

.user-flair:empty {
  margin-left: 0;
}

.form-wrapper {
  background-color: #1a162d;
  padding-top: 20px;
  padding-bottom: 20px;
}

.form-completed-at {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #2a2a2a;
  padding-left: 16px;
}

.dobInput {
  width: 25%;
}

.form-question {
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #2a2a2a;
}

.form-qa-container {
  padding-left: 16px;
}

.scroll-to-bottom {
  right: 50px;
  cursor: pointer;
  background: #4168ca;
  font-size: 15px;
  position: absolute;
  border-radius: 5px;
  padding: 6px;
  bottom: 35px;
  color: #fff;
  z-index: 2;
  font-weight: 700;
}

.hide-scroll {
  display: none;
}

.form-answer {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #2a2a2a;
}

.form-loading {
  display: flex;
  justify-content: center;
}

.page-title {
  font-size: 23px;
  color: $blue;
  font-family: "Roboto", sans-serif;
}

.get-connected {
  font-size: 16px;
  color: $white;
  font-family: "Roboto", sans-serif;

  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
}

.screen-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.csm-title {
  font-size: 20px;
  color: $white;
  font-family: "Roboto", sans-serif;

  @media screen and (max-width: 375px) {
    font-size: 16px;
  }
}

.terms-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
}

.sub-container {
  background-color: #d8d8d8;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.sub-title {
  font-size: 16px;
  color: #006eb2;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  margin-right: 10px;
}

.background-color {
  @media screen and (max-width: 575px) {
    background-color: #d8d8d8;
  }
}

.triangle-shape-info {
  width: 0;
  height: 0;
  border-top: 30px solid#1c152d;
  border-right: 30px solid transparent;
}

.triangle-shape {
  width: 0;
  height: 0;
  border-top: 30px solid#1c152d;
  border-right: 30px solid transparent;

  @media screen and (max-width: 475px) {
    width: 0;
    height: 0;
    border-top: 45px solid#1c152d;
    border-right: 45px solid transparent;
  }
}

.info {
  background-color: #1c152d;
  color: white;
  flex-wrap: nowrap;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  padding: 5px 10px 5px 15px;
  font-weight: 600;
}

.info-reset-password {
  display: flex;
}

.info-icon {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.consentdownload-icon {
  height: 50px;
  width: 50px;
}

.info-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.relative-position {
  position: relative;
  left: -22px;
}

.checkbox-container {
  padding-left: 34%;
}

.filter-by {
  padding: 5px;
  border-radius: 5px;
  background-color: #e5eef4;
}

.search-member-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-right: 50px;
  padding-left: 50px;

  .dropdown-input-container {
    display: flex;
    margin-right: 30px;
    margin-bottom: 1px;

    .member-dropdown {
      background: #ef472d;
      border-radius: 3px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      padding-right: 8px;
      color: white;
      font-weight: 700;
      width: auto;
      justify-content: center;
    }

    .member-input {
      width: 250px;
      border-radius: 3px;
      border: 1px solid #777f95;
      box-sizing: border-box;
    }
  }
}

.not-supported {
  color: red;
  font-weight: 600;
}

.sites-container {
  position: absolute;
  background-color: whitesmoke;
  overflow-y: scroll;
  height: 250px;

  .menu {
    display: contents;

    .menu-item {
      width: 100%;
    }
  }
}

.password-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  .password-field {
    padding-right: 30px;
  }

  .eye-container {
    position: absolute;
    right: 10px;
  }
}

.site-dropdown {
  display: flex;
  justify-content: space-between;
  align-content: center;
  border: 1px solid #e2e2e2;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.csm-signup-container {
  .form-group {
    padding-left: 20px;
    padding-right: 20px;

    input {
      border: 1px solid #e2e2e2;
      font-family: "Conv_AvenirNextLTPro-It";
      font-size: 12px;
      color: $gray-100;

      &:focus {
        border: 2px solid #c6e0ff;
        box-shadow: 0 0 5px #c6e0ff;
      }
    }
  }
}



.noLogo {
  max-width: 400px;
  width: 100%;

  input {
    border: 2px solid #fff;
    font-family: "Conv_AvenirNextLTPro-It";
    font-size: 12px;
    color: $gray-100;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
      border-left: 2px solid rgba(98, 116, 230, 0.8);
    }
  }

  select {
    border: 2px solid #fff;
    font-family: "Conv_AvenirNextLTPro-It";
    font-size: 12px;
    color: $gray-100;

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
      border-left: 2px solid rgba(98, 116, 230, 0.8);
    }
  }

  .form-group {
    span {
      float: right;
      left: -15px;
      margin-top: -30px;
      position: relative;
      z-index: 2;
      font-size: 12px;
      color: $gray-100;
    }
  }

  .sign-up {
    float: right;
  }

  p {
    font-size: 12px;
  }

  .col-sm-7 {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: -15px;
      border: 1px solid $border-color;
    }
  }
}

.input-label {
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.caution {
  padding-top: 5px;
  font-size: 12px;
}

.check-box {
  margin-top: 3px;
  margin-right: 5px;
}

.navbar-toggler {
  display: none;
}

.warning {
  color: red;
  font-size: 12px;
}

.waiting-badge {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 5px;
  color: #fff;
  background-color: #5656ff;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.group-head {
  color: #2b2b3d;
  font-size: 18px;
  font-family: "Conv_AvenirNextLTPro-Bold";
}



.approve-spin {
  position: absolute;
  left: 40%;
  top: 50%;
}

.pauseAll-spin {
  position: absolute;
  left: 44%;
  top: 44%;
  z-index: 1;
}

.details-loading {
  position: absolute;
  left: 48%;
  top: 50%;
}

.approved-users {
  margin-top: 40px;
  margin-bottom: 40px;
  background: #e64821;
  text-align: center;
  font-size: 16px;
  padding: 5px;
  font-weight: 700;
  color: white;
}

.loading-alert-members {
  display: flex;
  justify-content: center;
}

.alert-spin {
  display: flex;
  flex: 1;
  justify-content: center;
  height: 200px;
  align-items: center;
}

.btn-custom {
  background: #eaeaec;
  border-radius: 9px;
  font-size: 18px;
  color: #d1d1d4;
  font-family: "Roboto";
  line-height: 22px;
}

.btn-custom.btn.logout {
  color: initial;
}

.right-sidebar {
  img {
    position: absolute;
    right: 15px;
  }

  .user-name {
    text-align: center;
  }
}

.user-flags {
  display: flex;
  justify-content: space-between;

  padding: 20px;
  color: #2a2a2a;

  .username {
    font-size: 20px;
    font-family: Roboto;
    font-weight: 700;
  }

  img {
    height: 22px;
    width: 22px;
  }
}

.groupHeading {
  font-family: Roboto;
  font-size: 18px;
  line-height: 25px;
  color: #2a2a2a;
  font-weight: 550;
}

.over-sized-group-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 700px;
}

.user-group-container {
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  padding-top: 10px;

  .user-group {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.user-group-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 30px;
  padding-bottom: 15px;

  .user-groups {
    padding-bottom: 20px;

    .user-group-name {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      color: #2a2a2a;
      padding-bottom: 10px;
      border-bottom: 1px solid #c3c7d1;
    }

    .user-group-status {
      margin-right: 10px;
    }
  }
}

.group-semiMods {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  align-items: center;
  color: #2a2a2a;
}

.recruiting-groups {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 700px;

  .recruiting-group {
    width: 350px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

.profile-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Dark Font */

  color: #2a2a2a;
}

.profile-groups-container {
  height: calc(100vh - 350px);
  overflow-y: scroll;
}

.group-session-container {
  height: calc(100vh - 250px);
  overflow-y: scroll;
  padding-bottom: 35px;
}

.group-image-container {
  display: flex;
  justify-content: center;
  align-self: center;
}

.group-users {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: grey;
  cursor: pointer;
}

.add-pointer {
  cursor: pointer;
}

.group-users-with-status {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: grey;
  display: flex;
  width: 151px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-group-name {
  width: 195px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}

.new-password {
  padding: 5px;
}

.reset-password-popover {
  display: flex;
  flex-direction: column;
}

.popover-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.confirm-button {
  width: 80px;
}

.save-as-draft {
  margin-left: 20px;
}

.submit-container {
  text-align: center;
  margin: 10px;
}

.profile-edit-image {
  cursor: pointer;
}

.profile-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #c3c7d1;
  padding-left: 30px;
  padding-right: 30px;
  height: 60px;
  margin-bottom: 20px;
}

.group-edit-input {
  height: 32px;
  border: 2px solid #4168ca;
  border-radius: 5px;
  width: 221px;
  padding: 5px;
}

.upload-image {
  position: relative;
  bottom: 98px;
  left: 69px;
  width: 180px;
}

.profile-type {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  /* identical to box height */
}

.edit-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cancel-button {
    margin-right: 14px;
    color: #4168ca;
    cursor: pointer;
  }

  .submit-button {
    margin-left: 14px;
    background: #4168ca;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    width: 100px;
  }
}

.username-signup {
  font-size: 16px;
  color: #000;
  padding-bottom: 8px;
  padding-top: 5px;

  .username-value {
    padding-left: 10px;
    color: #e85533;
    font-weight: 700;
  }
}

.password-signup {
  font-size: 16px;
  color: #000;
  padding-bottom: 8px;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.forgotten-password {
  font-style: italic;
  color: #000;
  margin-bottom: 25px;
  font-size: 14px;
}

.issues {
  display: flex;
  flex-direction: row;

  .help-icon {
    font-size: 20px;
    margin-right: 5px;
  }

  .having-issues {
    font-size: 14px;
    color: #000;
  }

  .support-link {
    cursor: pointer;
    color: #1890ff;
  }
}

.userSignUpImagesContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  padding-bottom: 35px;

  .osspecific-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .userSignUpImages {
    height: 40px;
  }

  .launcherIosImage {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .launcherAndroidImage {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-bottom: 10px;
  }
}

.profile-content {
  display: flex;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;

  .profile-image {
    width: 200px;
    height: 200px;
    background: #c4c4c4;
  }

  .item-desc {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 8px;

    .mainHeading {
      font-family: Roboto;
      font-size: 18px;
      line-height: 25px;
      color: #2a2a2a;
      font-weight: 550;
      display: inline-block;
      max-width: 350px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.mainHeading {
  font-family: Roboto;
  font-size: 18px;
  line-height: 25px;
  color: #2a2a2a;
  font-weight: 550;
  display: inline-block;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;

  &.left-move {
    margin-left: 10px;
  }
}

.notes-mainHeading {
  font-family: Roboto;
  font-size: 18px;
  // line-height: 25px;
  color: #2a2a2a;
  font-weight: 550;
  // display: inline-block;
  max-width: 840px;
  // text-overflow: ellipsis;
  overflow: hidden;
}

.pre-mainHeading {
  overflow: hidden;
  // text-overflow: ellipsis;
}

.download-button {
  background: #4168ca;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 145px;
}

.session-button {
  background: #4168ca;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 145px;
  padding: 10px;
  color: #fff;
  font-weight: 400;
}

.session-buttons {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  margin-right: 10px;
}

.userDetails-container {
  margin: 20px 48px;
  border: 1px solid #9da3b3;
  border-top: 0;
}

.lightblue-background {
  position: relative;
  z-index: 1;
  background-color: #cfdcfe;
  border: 2px solid #4168ca !important;

  &#display-data {
    padding: 5px;
  }
}

.white-border {
  border: 2px solid #fff;
}

.subHeading {
  font-family: Roboto;
  font-style: normal;
  font-size: 18px;
  line-height: 25px;
  font-weight: 300;

  a {
    font-family: Roboto;
    font-size: 18px;
    line-height: 25px;
    text-decoration-line: underline;
    color: #4168ca;
  }
}

.alert-loading {
  width: 20px;
  height: 20px;
  position: relative;
  top: 250px;
  left: 500px;
}

.add-to-groups-loading {
  position: absolute;
  top: 250px;
  left: 120px;
}

.show-undeleted-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #4168ca;
  margin-bottom: 10px;
  border-radius: 3px;
}

.delete-account-container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.custom-banner-image {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  padding: 10px;
}

.custom-banner-emoji {
  font-size: 65px;
  position: absolute;
  top: 0;
}

.custom-banner-container {
  position: relative;
  background: $btn-default;
  min-width: 10rem;
  max-width: 600px;
  word-break: break-word;
  padding: 5px;
  border-radius: 4px;
  display: inline-flex;
  min-height: 145px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-banner-message {
  position: relative;
  top: 82px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.undeleted-icon {
  height: 20px;
  width: 20px;
}

.user-bio-container {
  display: flex;
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;


  .label {
    color: red;
    text-align: center;
    display: flex;
    font-weight: 500;
    align-items: center;
    margin-left: 20px;
    font-size: 15px;
  }

  .user-bio {
    flex: 1;

    .mainHeading {
      font-family: Roboto;
      font-size: 18px;
      line-height: 25px;
      color: #2a2a2a;
      font-weight: 550;
      display: inline-block;
      max-width: 350px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .input {
      background: #fcfcfc;
      /* Dark Font */
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: 1px solid #2a2a2a;
      box-sizing: border-box;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      font-family: Roboto;
      font-size: 18px;
      line-height: 25px;
    }

    ::placeholder {
      color: lightgray;
      opacity: 1;
    }

    .questionInput {
      background: #fcfcfc;
      /* Gray Text */
      font-family: Roboto;
      font-style: normal;
      font-weight: 550;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      border: 1px solid #777f95;
      box-sizing: border-box;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding-left: 18px;
      padding-right: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 100%;
    }

    .subHeading {
      font-family: Roboto;
      font-style: normal;
      font-size: 18px;
      line-height: 25px;
      font-weight: 300;

      a {
        font-family: Roboto;
        font-size: 18px;
        line-height: 25px;
        text-decoration-line: underline;
        color: #4168ca;
      }
    }
  }

  .radio-group {
    display: flex;
    flex-direction: column;
  }

  .notes-textArea {
    border: 1px solid #777f95;
    box-sizing: border-box;
    border-radius: 8px;
    width: 840px;
    height: 96px;
  }

  .download-button {
    background: #4168ca;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    width: 145px;
  }

  .delete-account-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    width: 145px;
    cursor: pointer;
    color: #4168ca;
    box-shadow: none;
    border: 0;
  }
}

.outreach-info-container {
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;

  .outreach-info-inner {
    margin-right: 10px;
  }
}

.group-image {
  height: 150px;
  margin-bottom: 10px;
  max-width: 300px;
}

.group-image-edit {
  height: 150px;
  margin-bottom: 10px;
  opacity: 0.2;
  max-width: 300px;
}

.user-flag-sidebar {
  position: fixed;
  height: 100vh;
  width: 370px;
  background: #ffffff;
  border: 1px solid #9da3b3;
  right: 0;
  z-index: 999;
  transition: all 0.2s ease-in-out;
}

.group-flag-sidebar {
  position: fixed;
  height: 100vh;
  width: 360px;
  background: #ffffff;
  border: 1px solid #9da3b3;
  right: 0;
  top: 24%;
  z-index: 999;
  transition: all 0.2s ease-in-out;
}

.member-type-heading {
  background: lightgray;
  padding: 5px;
}

.group-members {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
}

.session-sidebar {
  position: fixed;
  height: 78vh;
  width: 370px;
  background: #ffffff;
  box-shadow: $boxshadow;
  right: 0;
  z-index: 1;
  transition: all 0.2s ease-in-out;

  .close-img {
    width: 20px;
    height: 20px;
  }

  .user-name {
    display: flex;
    justify-content: space-between;
  }

  .group-name-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8px;
  }

  .group-edit-loading {
    position: absolute;
    left: 44%;
  }

  .group-edit-image {
    width: 180px;
  }

  .group-edit-preview {
    width: 180px;
    height: 60px;
    margin-bottom: 10px;
  }

  .group-name {
    position: relative;
    width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .user-name {
    position: relative;
    padding-left: 40px;
    word-break: break-word;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 26px;
      height: 26px;
      background: $dark-text;
      border-radius: 26px;
    }
  }
}

.send-banner-container {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
}

.session-text-area {
  padding: 5px;
  padding-right: 5px;
  font-size: 16px;
  font-weight: 400;
}

.user-sidebar {
  position: fixed;
  height: 100vh;
  width: 370px;
  background: #ffffff;
  box-shadow: $boxshadow;
  right: 0;
  z-index: 6;
  transition: all 0.2s ease-in-out;

  .close-img {
    width: 20px;
    height: 20px;
  }

  .user-name {
    display: flex;
    justify-content: space-between;
  }

  .group-name-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8px;
  }

  .group-edit-loading {
    position: absolute;
    left: 44%;
  }

  .group-edit-image {
    width: 180px;
  }

  .group-edit-preview {
    width: 180px;
    height: 60px;
    margin-bottom: 10px;
  }

  .group-name {
    position: relative;
    width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .user-name {
    position: relative;
    padding-left: 40px;
    word-break: break-word;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 26px;
      height: 26px;
      background: $dark-text;
      border-radius: 26px;
    }
  }
}

.chat-image-upload {
  display: none;
}

.user-profile-sidebar {
  position: fixed;
  height: 100vh;
  width: 72%;
  background: #ffffff;
  box-shadow: $boxshadow;
  right: 0;
  z-index: 3;
  transition: all 0.2s ease-in-out;

  .header-section {
    background: #ffffff;
    box-shadow: 0 7px 15px 0 rgba(165, 165, 165, 0.2);
    position: relative;
    padding-top: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #9da3b3;
    display: flex;
    justify-content: space-between;
    padding-left: 60px;
    padding-right: 60px;

    h1 {
      margin: 0;
      word-break: break-word;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      text-align: center;
    }
  }
}

.close-invite {
  position: absolute;
  right: 25px;
}

.show-flags-groups {
  text-align: center;
  padding: 10px;
  border: 1px solid #4168ca;
  margin-bottom: 10px;
  border-radius: 3px;

  span {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.flag-actions-container {
  display: flex;

  img {
    height: 24px;
    width: 24px;
    margin-left: 20px;
  }
}

.actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-right: 12px;
}

.profile-sidebar {
  position: fixed;
  height: 100vh;
  width: 522px;
  background: #ffffff;
  box-shadow: $boxshadow;
  right: 0;
  top: 68px;
  z-index: 4;
  padding-bottom: 175px !important;
  transition: all 0.2s ease-in-out;

  img {
    width: 20px;
    height: 20px;
  }

  .user-name {
    display: flex;
    justify-content: space-between;
  }

  .user-name {
    position: relative;
    padding-left: 40px;
    word-break: break-word;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 26px;
      height: 26px;
      background: $dark-text;
      border-radius: 26px;
    }
  }
}

.Added-by {
  margin-top: 10px;
}

.added-by-username {
  font-size: 15px;
  font-weight: 600;
  color: #2a2a2a;
}

.left-alert-sidebar,
.right-sidebar {
  height: calc(100vh - 70px);
  width: 28%;
  background: #ffffff;
  box-shadow: none;
  position: relative;
  z-index: 9;
  overflow-y: scroll;
}

.right-sidebar {
  height: calc(100vh - 130px) !important;
}

.chatSection {
  flex: 1;
  z-index: 99;
}

.champion-name {
  font-weight: 100;
  color: gray;
}

.history-of-activities {
  .activity-line {
    font-size: 16px;
    line-height: 20px;
    padding: 5px;
  }

  .source-user {
    font-weight: 600;
    color: maroon;
  }

  .target-user {
    font-weight: 600;
    color: coral;
  }

  .target-field {
    font-weight: 600;
    color: #cd4444;
  }

  .old-field {
    font-weight: 600;
    color: #ce883d;
  }

  .new-field {
    font-weight: 600;
    color: #5fd039;
  }

  .target-group {
    font-weight: 600;
    color: #2a2a2a;
  }

  .channel-name {
    font-weight: 600;
    color: navy;
  }

  .form-listing {
    padding: 10px;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-name {
      width: 12%;
    }

    .form-listing-details {
      text-align: center;
      width: 30%
    }

    button {
      span {
        text-align: center;
      }

      width: 22%;
      right: 20px;
      align-self: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .activity {
    padding: 10px;
    border-bottom: 1px solid lightgray;

    .date {
      color: grey;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.seeHistory-container {
  .history-element {
    padding-bottom: 18px;
    padding-right: 36px;
    padding-top: 18px;
    padding-left: 36px;
    border-bottom: 1px solid #c3c7d1;

    .time {
      font-family: Roboto;
      font-style: normal;
      margin-bottom: 12px;
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      color: #2a2a2a;
    }

    .dm {
      font-family: Roboto;
      font-size: 18px;
      line-height: 24px;
      color: #2a2a2a;
    }

    .activity {
      font-family: Roboto;
      font-size: 18px;
      line-height: 24px;
      color: #2a2a2a;
    }
  }
}

.group-image-upload {
  margin: 10px 5px;
}

.invert-color {
  filter: invert(1) brightness(1.9);
}



.modSemiMod-img {
  margin-left: 10px;
}

.content-container {
  border-bottom: 2px solid black;
  padding: 10px;

  .form-createdAt {
    font-size: 18px;
    color: #2a2a2a;
    margin-right: 10px;
    font-weight: 600;
  }
}

.single-alert {
  position: absolute;
  left: 37%;
  top: 50%;
  cursor: pointer;
  background: #4168ca;
  font-size: 15px;
  border-radius: 5px;
  padding: 6px;
  color: #fff;
  font-weight: 700;
}

.form-username {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background: rgba(216, 216, 216, 1);
}

.form-responses {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 5px;
  padding-top: 5px;

  span.ant-radio+* {
    font-weight: 500;
  }
}

.form-response {
  display: flex;
  flex-direction: column;
  margin-left: 48px;
  margin-right: 48px;
  border: 1px solid #9da3b3;
  margin-top: 20px;
  zoom: 90%;

  ::placeholder {
    color: #C7C7CD
  }
}

.form-heading {
  margin: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 1rem;
  align-items: center;

  .form-heading-close-img {
    width: 25px;
    height: 25px;
  }

  .form-heading-minimize-img {
    width: 25px;
    height: 30px;
    margin-right: 5px;
  }
}

.mod-form-container {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #78496a;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;

  .mod-form-loading {
    position: relative;
    left: 45%;
  }

  .form-count {
    font-size: 22px;
    line-height: 28px;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }

  .form-desc {
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
  }

  .form-text {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }

  .form-go {
    height: 40px;
    display: flex;
    width: 40px;
    background-color: #6acc86;
    border: 2px solid #fff;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .form-completed {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
  }

  .form-already {
    height: 40px;
    display: flex;
    width: 40px;
    background-color: #6acc86;
    border: 2px solid #fff;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
  }

  .form-go-text {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
  }
}

.form-deleted {
  border-radius: 10px;
  background-color: red;
}



.reaction-row {
  display: flex;
  padding: 4px;
  border-bottom: 1px solid lightgray;
  justify-content: space-between;
  align-items: center;
}

.reaction-row-flag {
  justify-content: center;
  margin-bottom: 2px;
}

.flag-delete-container {
  display: flex;
  flex-direction: column;
}

.reaction-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.reactions-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 180px;
}

.last-seen-icon {
  position: relative;
  top: 20px;
  left: 14px;
}

.reaction-container {
  min-width: 100px;
}

.message-reactions-count {
  margin-left: 30px;
  margin-top: -12px;
  position: relative;

  .badge {
    border: 1px solid #ddd;
    min-width: 55px;
    height: auto !important;
    width: auto !important;
    border-radius: 15px;
    text-align: left;
    padding-left: 7px;
    padding-right: 15px;
    background: #fff;

    .message-reactions {
      height: 18px;
      width: 18px;
    }
  }
}

.message-reactions {
  &.clickable {
    cursor: pointer;
  }

  .reaction {
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }

  &.selected {
    background-color: $btn-default;
    border: 5px solid $btn-default;
    border-radius: 5px;
  }
}

.selected-group-loading {
  position: relative;
  top: 45%;
  left: 45%;
}

.group-chat {
  .list-group-item {
    border: none !important;
    padding: 0.75rem 1rem;
    cursor: pointer;

    .badge {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-left: 5px;
    }

    h3 {
      word-break: break-word;
      display: inline;
    }

    small {
      color: $gray-100;
      font-size: 14px;
    }

    &.active {
      background: $active;
    }
  }

  .member-selection-list {
    height: calc(100vh - 150px);
    overflow-y: scroll;
  }
}

.showChildDiv {
  .user-sidebar {
    top: 0;
  }
}

.badge {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 5px;
}

.unread-messages {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: -8px;
    top: -2px;
    width: 8px;
    height: 8px;
    background: $primary;
    border-radius: 8px;
  }
}

#alert-display-container {
  height: calc(100vh - 150px);
  overflow-y: scroll;
}




.link-text {
  color: #1890ff;
  cursor: pointer;
}

.header-link {
  color: initial;
  text-decoration: none;

  &:hover {
    color: initial;
  }
}

.chatSection {
  .ant-tabs-nav {
    padding-bottom: 0px;
  }
}

.my-tabs-ink-bar.ant-tabs-ink-bar {
  color: red;
}

.alert-tab {
  font-family: "Lato", Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
}

.reset-password {
  font-family: Roboto;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-decoration-line: underline;

  /* Secondary */

  color: #4168ca;
}

.waiting-queue-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.flag-container {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 18px;

  .flag-upper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .flag-name-container {
      display: flex;
      flex-direction: row;

      .flag-name {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #e64821;
      }

      .flag-profile-container {
        cursor: pointer;
        margin-top: 2px;
      }

      .flag-profile {
        background: #4168ca;
        /* Secondary */
        margin-left: 10px;
        border: 1px solid #4168ca;
        border-radius: 10px;
        padding-left: 8px;
        padding-right: 8px;
        height: 20px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 520;
        line-height: 14px;
        text-align: center;
        vertical-align: middle;
        display: flex;
        justify-content: center;
        color: #fff;
        align-items: center;
        letter-spacing: -0.2px;
        width: max-content;
      }
    }

    .flag-time {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      width: 160px;
      text-align: end;
    }
  }

  .flag-group {
    font-family: Roboto;
    font-style: normal;
    font-size: 15px;
    line-height: 25px;
    font-weight: 100;
    color: #2a2a2a;
  }

  .flag-middle {
    margin-top: 10px;
    word-break: break-word;

    .flag-message-container {
      display: flex;
      flex-wrap: wrap;

      .heading {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        font-family: Roboto;
        font-size: 18px;
        line-height: 24px;
        color: #2a2a2a;
        font-weight: bold;
      }
    }

    .heading {
      font-family: Roboto;
      font-size: 18px;
      line-height: 24px;
      color: #2a2a2a;
      font-weight: 600;

      .flag-user-name {
        text-decoration: underline;
        color: #4168ca;
        cursor: pointer;
      }
    }

    .subHeading {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #2a2a2a;
    }
  }

  .flag-lower {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 15px;

    .flag-buttons {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height */

      display: flex;
      align-items: center;

      color: #f5f5f5;
    }

    .flag-div {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }

    .flag-icon {
      font-size: 35px;
      color: #4168ca;
    }
  }
}

.flag-message-button {
  background: #4168ca;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #f5f5f5;
  border-radius: 6px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-right: 14px;
}

.no-alerts {
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
  color: #2a2a2a;
  font-weight: 600;
  text-align: center;
}

.flag-resolve-button {
  background: #fff;
  border: 0;
  margin-left: 24px;
  color: #4168ca;
  font-size: 18px;
}

.see-messages-button {
  background: #fff;
  border: 0;
  color: #4168ca;
  font-size: 18px;
}

.content-item {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  align-items: center;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.content-button {
  width: 100%;
  height: 35px;
}

.horizontal-tag {
  margin: 0;
  border: 0;
  border-top: 1px solid rgba(11, 11, 11, 0.1);
}

.dm-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-right: 10px;

  :only-child {
    margin-left: auto;
  }
}

.dm-loading {
  display: flex;
  justify-content: center;
  position: relative;
  top: 150px;
}

.close-img {
  vertical-align: middle;
  height: 20px;
  width: 20px;
}

.broadcast-close {
  background: #ffc9ca;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.broadcast-start {
  background: #f2f2f2;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}


.list-content-item {
  cursor: pointer;
}

.list-content-item:hover {
  color: #4168ca;
  background: aliceblue;
  width: 100%;
  vertical-align: middle;
}

.show-admins {
  font-size: 15px;
  font-weight: 600;
  color: #2a2a2a;
}

.left-sidebar2 {
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);
  height: calc(100vh - 70px);
  padding: 15px;

  .show-mem-checkbox {
    .ant-checkbox-inner {
      width: 22px;
      height: 22px;
    }

    .ant-checkbox-checked {
      &:after {
        border-color: #018b01;
      }

      .ant-checkbox-inner {
        background: #018b01;
      }
    }
  }

  .list-group-item {
    .ant-checkbox-wrapper {
      margin-right: 10px;

      .ant-checkbox-inner {
        border-radius: 50%;
      }

      .ant-checkbox-checked {
        &:after {
          border-color: #018b01;
        }

        .ant-checkbox-inner {
          background: #018b01;
          border-color: #018b01;
        }
      }
    }
  }
}

.smile {
  font-size: 25px;
  color: #4168ca;
}

.color-box {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.emoji-box {
  padding: 0;
}

.creating-event {
  position: absolute;
  left: 48%;
  top: 45%;
}

.center-loading {
  position: absolute;
  left: 50%;
  top: 40%;
}

.ant-dropdown-menu {
  display: flex;
  flex-wrap: wrap;
  position: ABSOLUTE !important;
  width: 104px;
  top: -90px;
  right: 15px;

  li {
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    width: 33%;
  }
}

.boardcast-chat-section {
  flex: 1;
  padding: 15px 45px;

  textarea {
    height: calc(100vh - 200px) !important;
  }

  .custom-btn {
    background: #5557ff !important;
    margin-top: 30px;
    min-width: 200px;
    color: #fff !important;
    border-color: #5557ff !important;
    min-height: 40px;
    border-radius: 10px !important;
  }

  .custom-btn-disabled {
    background: lightsteelblue;
    margin-top: 30px;
    min-width: 200px;
    color: #5557ff !important;
    border-color: #5557ff !important;
    min-height: 40px;
    border-radius: 10px !important;
  }
}

.assign-input-container {
  background: #fcfcfc;
  height: 72px;
  display: flex;
  align-items: center;

  .assign-input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 16px;
    padding-left: 16px;
    margin-left: 36px;
    margin-right: 36px;
    width: 100%;
  }
}

.admin-name-item {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 15px;
  padding-left: 56px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.admin-name-container :nth-child(odd) {
  background: #eaebee;
}

.chat-username {
  margin-top: -15px;
  margin-left: 5px;
}

.chat-user-mod-tag {
  margin-left: 5px;
}

.chat-user-mod-tag:empty {
  margin-left: 0;
}

.chat-user-mention-button {
  align-self: center;
  margin-left: 10px;
}

.reload-icon {
  margin-left: 10px;
  vertical-align: 0.125em !important;
  font-size: 0.9em;
}

.enrolledTag {
  width: auto;
  height: 21px;
  margin: 5px 5px;
}

.AHTag {
  color: white;
  padding: 2px 12px 2px 12px;
  border-radius: 35px;
  margin: 5px 5px;
  font-size: 12px;
  font-weight: 700;
}

.closeIcon {
  text-align: right;
}

.modal-block {
  margin: 10px;
}

.flag-option-select {
  margin-left: 50px;
  width: 180px;
}

.radio-block-flag {
  display: inline-flex;
  float: right;
}

.blueFlagDescription {
  color: #2E81E2;
}

.redFlagDescription {
  color: #E96162;
}

.display-flagmessage-Container {
  .message-date {
    margin: 0 auto;
    text-align: center;

    span {
      padding: 3px 5px;
      font-size: 12px;
      border-radius: 5px;
      background: #fdd5b4;
    }
  }

  .user-badge {
    display: inline-block;
    margin-right: 5px;
  }

  .username {
    cursor: pointer;
  }

  .img-message {
    width: 50%;
  }

  #display-data .chatText {
    max-width: 86% !important;
  }
}

.gif-search-bar {
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}

.gif-img-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  overflow-y: scroll;
  max-height: 600px;

  .gif-img-inner {
    width: 33.33%;
    padding: 8px 8px 0;
    cursor: pointer;

    em {
      min-width: 150px;
      min-height: 150px;
      line-height: 0;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.load-more-btn {
  padding: 3px;
  border-radius: 7px;
  font-weight: 600;
}

.bar-chart-span {
  font-size: 18px;
  font-weight: 400;
  // margin-left: 330px;
  text-align: center;
}

.bar-chart-inner {
  font-size: 14px;
  font-weight: 600;
  margin-left: 40px;
}

.inception-header {
  margin-left: 40px;
}

.program-summary-header {
  text-align: center;
  font-size: 25px;
}

.app-usage-header {
  text-align: center;
  font-size: 25px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.pie-chart-header {
  text-align: center;
  font-size: 25px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.insights-report-wrapper {
  width: 800px;
  max-width: 100%;
  position: absolute;
  top: 15%;
  left: 30%;
  // width:800px;
  // max-width: 100%;
  margin: 25px 0;
  // margin: 25px 0;

  .pie-chart-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: 930px;

    .pie-chart-inner {
      width: 100%;
      text-align: center;

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .group-summary-header {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: 50px;
  }

  .groups-list-header,
  .providers-header {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 15px;
  }

  .active-groups-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid #FF4D00;
    border-radius: 10px;
    margin-bottom: 15px;

    .active-groups-image {
      width: 315px;
      height: 150px;
      float: right;
      padding-right: 5px;
    }

    .active-groups-inner {
      width: 50%;

      h4 {
        font-size: 16px;
        padding-top: 20px;
        padding-left: 15px;
        margin-bottom: 15px;
      }

      .members-count {
        padding-left: 15px;
        font-size: 14px;
      }

      .members-messages-count {
        padding-left: 15px;
        font-size: 14px;
      }

      .group-description {
        padding-left: 15px;
        font-size: 14px;
      }
    }

  }

  .group-data-wrapper {
    display: flex;
    flex-wrap: wrap;
    // margin:0 -15px;
    border-spacing: 5px;

    .group-data-inner {
      width: 50%; //33.3%

      // padding:0 15px 30px;
      .count-header {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        justify-content: center;
      }

      h3 {
        font-size: 20px;
        // font-weight: 400;
        color: #000000;
        margin-bottom: 10px;
        text-align: center;
      }

      .group-information {
        // width:250px;
        max-width: 100%;
        // margin:0 auto;
        border: 1px solid #FF4D00;
        border-radius: 15px;
        text-align: center;
        padding: 13px;

        h2 {
          font-size: 28px;
          font-weight: 600;
          color: #FF4D00;
          margin-bottom: 10px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .providers-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    .providers-list-inner {
      width: 50%;
      padding: 0 15px 30px;

      .provider-information {
        width: 300px;
        max-width: 150%;
        // margin:0 auto;
        margin-left: 0;
        border: 1px solid #FF4D00;
        border-radius: 10px;
        text-align: left;
        padding: 13px;
        height: 130px;

        h2 {
          font-size: 28px;
          font-weight: 600;
          color: #FF4D00;
          margin-bottom: 10px;
        }

        span {
          font-size: 14px;
          font-weight: 600;
          margin-left: 10px;
        }
      }
    }
  }

  .groups-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    .groups-list-inner {
      width: 50%;
      padding: 0 15px 30px;

      .group-information {
        width: 300px;
        max-width: 150%;
        // margin:0 auto;
        margin-left: 0;
        border: 1px solid #FF4D00;
        border-radius: 10px;
        text-align: left;
        padding: 13px;
        height: 250px;

        h2 {
          font-size: 20px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 15px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .count-data-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    // margin:0 -15px;
    .count-name-box {
      min-width: 300px;

      h3 {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: #000;
        text-transform: capitalize;
      }
    }

    .count-data-inner {
      width: 33.3%; //33.3%

      // padding:0 15px 30px;
      .count-header {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        justify-content: center;
        margin-right: 15px;
      }

      h3 {
        font-size: 20px;
        // font-weight: 400;
        color: #000000;
        margin-bottom: 10px;
        text-align: center;
        margin: 0
      }

      .main-count-wrapper {
        // width:250px;
        max-width: 100%;
        // margin:0 auto;
        border: 1px solid #FF4D00;
        border-radius: 15px;
        text-align: center;
        // padding: 10px;
        padding-top: 10px;

        h2 {
          font-size: 28px;
          font-weight: 600;
          color: #FF4D00;
          margin-bottom: 10px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

}

.stats-overview-container {
  .stats-overview-loading {
    text-align: center;
    margin-top: 40px;
  }

  .annual-goal-container {
    border: 1px solid #ffffff;
    height: 100%;
    border-radius: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(129, 152, 159, 0.278546);
    ;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;

    h4 {
      margin-left: 30px;
      margin-top: 10px;
      font-size: 17px;
      font-weight: 500;
    }

    .no-data-found {
      text-align: center;
    }

    .app-health-message-data-first-section {
      margin-top: 7%;
    }

    .app-health-message-data-second-section {
      margin-top: 2%;
    }

    .message-overview-heading {
      margin-top: 10px;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }

    .img-expand {
      margin-left: 40px;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    .users-wau-chart-wrapper {
      text-align: center;
      margin-top: 30px;

      .recharts-legend-wrapper {
        bottom: 10px;
      }

      span {
        font-weight: bold;
      }
    }

    .site-based-wau-chart-wrapper {
      text-align: center;
      margin-top: 30px;

      .sites-wau-chart-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 300px;
      }

      .sites-list-wrapper {
        border: 2px solid #FB9048;
        border-radius: 14px;
        width: 20%;
        height: 300px;
        margin-left: 5%;
        padding-top: 20px;
        padding-bottom: 20px;
        overflow-y: scroll;
      }

      .territories-list-wrapper {
        border: 2px solid #FB9048;
        border-radius: 14px;
        width: 20%;
        height: auto;
        margin-left: 5%;
        padding-top: 20px;
        padding-bottom: 20px;

        .ant-checkbox-group {
          justify-content: left;
        }

        .ant-checkbox-group-item {
          text-align: left;
          margin-left: 35px;
        }

        .ant-checkbox-group-item .ant-checkbox-wrapper {
          justify-content: left;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background-color: #FF4D00;
        }
      }

      .territories-list-view {
        text-align: left;

        .ant-checkbox-wrapper {
          width: 90%;
          margin-left: 20px;

          span {
            font-size: 16px;
            font-weight: bold;
          }
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background-color: #FF4D00;
        }
      }

      .territory-options-view {
        span {
          font-weight: 500;
        }
      }

      .sites-list-view {
        flex-direction: row;
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
        height: 40px;
        justify-content: space-between;

        .site-name {
          font-weight: bold;
          font-size: 16px;
          width: 85%;
          text-align: left;
        }
      }

      .site-based-container {
        width: 80%;
      }

      .site-based-wau-chart {
        text-align: left;
      }

      span {
        font-weight: bold;
      }
    }

    .week-message-data-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .last-week-users-data,
      .current-week-users-data {
        width: 40%;
        margin-left: 5%;
        margin-bottom: 5%;

        .last-week-data,
        .current-week-data {
          display: block;
          overflow-y: auto;
          height: 200px;
        }

        h4 {
          text-align: center;
        }

        table {
          // display: block;

          width: 100%;

          // border: 1px solid #000000;
          th,
          td {
            text-align: center;
          }

          tr {
            border: 1px solid #000000;
          }

          .left-align {
            text-align: left;
            padding-left: 10px;
          }

          .row-green {
            background-color: #C6EFCE;
          }

          .row-red {
            background-color: #FFC7CE;
          }

          .row-yellow {
            background-color: #FFEB9C;
          }
        }
      }

    }

    .retention-month-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .last-month-users-data,
      .current-month-users-data {
        width: 40%;
        margin-left: 5%;
        margin-bottom: 5%;

        .last-month-data,
        .current-month-data {
          display: block;
          overflow-y: auto;
          height: 400px;
        }

        h4 {
          text-align: center;
        }

        table {
          width: 100%;

          // border: 1px solid #000000;
          th,
          td {
            text-align: center;
          }

          tr {
            border: 1px solid #000000;
          }

          .left-align {
            text-align: left;
            padding-left: 10px;
          }

          .row-green {
            background-color: #C6EFCE;
          }

          .row-red {
            background-color: #FFC7CE;
          }

          .row-yellow {
            background-color: #FFEB9C;
          }
        }
      }

    }

    .enrolled-overview-wrapper {
      h5 {
        text-align: center;
      }

      .enrolled-overview-inner {
        width: 30%;
        margin-left: 35%;

        td {
          font-weight: bold;
          text-align: center;
        }

        .left-align {
          text-align: left;
          padding-left: 10px;
        }
      }
    }

    .enrolled-table-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5%;
      margin-bottom: 5%;
      width: 100%;
      justify-content: space-evenly;

      .enrolled-month-data,
      .enrolled-week-data {
        width: 35%;
        margin-left: 5%;
        border: 1px solid #000000;

        th {
          border-bottom: 1px solid #000000;
        }

        th,
        td {
          text-align: center;
          font-weight: bold;
        }

        .left-align {
          text-align: left;
          padding-left: 10px;
        }

        .right-border {
          border-right: 1px solid #000000;
        }

        .border-view {
          border: 2px solid #000000;
        }

        .border-view-left {
          border: 2px solid #000000;
          border-right: 0
        }

        .border-view-right {
          border: 2px solid #000000;
          border-left: 0
        }
      }
    }

    .engaged-goal-overview {
      width: 30%;
      margin-left: 35%;
      margin-bottom: 5%;

      th,
      td {
        text-align: center;
        font-weight: bold;
      }

      .left-align {
        text-align: left;
        padding-left: 10px;
      }
    }

    .engaged-goal-data {
      width: 50%;
      margin-left: 30%;
      margin-bottom: 5%;

      th,
      td {
        text-align: center;
        font-weight: bold;
      }

      .left-align {
        text-align: left;
        padding-left: 10px;
      }

      .border-view-engaged {
        border: 1px solid #000000;
      }

      .row-green {
        background-color: #C6EFCE;
      }
    }

    .outreach-goal-overview {
      width: 70%;
      margin-left: 15%;
      margin-bottom: 5%;

      th,
      td {
        text-align: center;
        font-weight: bold;
      }

      .left-align {
        text-align: left;
        width: 23%; //-> It was removed on island
        padding-left: 10px;
      }

      .border-view-engaged {
        border: 1px solid #000000;
      }
    }

    .outreach-goal-data {
      width: 70%;
      margin-left: 15%;
      margin-bottom: 5%;

      th,
      td {
        text-align: center;
        font-weight: bold;
      }

      .left-align {
        text-align: left;
        width: 23%; //-> It was removed on island
        padding-left: 10px;
      }

      .border-view-engaged {
        border: 1px solid #000000;
      }
    }

    .retention-goal-overview {
      width: 35%;
      margin-left: 35%;
      margin-bottom: 5%;

      th,
      td {
        text-align: center;
      }

      .font-bold {
        font-weight: bold;
      }

      .border-view-bottom {
        border-bottom: 2px solid #000000;
      }

      .left-align {
        text-align: left;
        padding-left: 10px;
      }
    }

    .app-health-data {
      .font-bold {
        font-weight: bold;
      }
    }

    .app-health-data,
    .support-table-months,
    .support-table-users {
      width: 50%;
      margin-left: 35%;

      th,
      td {
        text-align: center;
      }

      .left-align {
        text-align: left;
        padding-left: 10px;
      }

      .border-view {
        border: 2px solid #000000;
      }

      .right-border {
        border-right: 2px solid #000000;
      }
    }

  }
}

.progress-bar-wrapper {
  position: relative;
  display: inline-block;
  width: 60%;
  margin-left: 30%;
  margin-bottom: 10px;

  .progress-bar-image-inner {
    position: absolute;
    top: -25px;
    left: 0;
    text-align: right;
  }
}

.progress-bar-wrapper-dual {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-left: 30%;
  margin-bottom: 10px;

  .progress-bar-image-inner {
    position: absolute;
    top: -25px;
    left: 0;
    text-align: right;
  }
}

.analytics-table-wrapper {
  width: 90%;
  border: 1px solid #FF4D00;
  border-radius: 8px;
  margin-top: 30px;
  margin-left: 5%;
  padding-top: 10px;
}

.single-territory-wrapper {
  width: 90%;
  border: 1px solid #FF4D00;
  border-radius: 8px;
  margin-top: 30px;
  margin-left: 5%;
  padding-top: 15px;
}

.territory-header {
  text-align: center;

  img {
    width: 10%;
  }
}

.wellness-form-container {
  padding-left: 30px;

  .wellness-form-loader {
    text-align: center;
  }

  .category-questions-container {
    margin-bottom: 10px;
  }

  .form-category-label {
    font-size: 18px;
    font-weight: 600;
  }

  .form-questions-wrapper {
    padding-left: 30px;
  }

  .form-question-title {
    width: 100%;
  }

  .question-answer-textarea {
    border: 1px solid #777f95;
    box-sizing: border-box;
    border-radius: 8px;
    width: 80%;
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;
  }
}

.wellness-answers-wrapper {
  font-family: Roboto;
  font-size: 18px;
  line-height: 25px;
  color: #2a2a2a;
  font-weight: 550;
  display: inline-block;
  max-width: 840px;
  overflow-wrap: break-word;
  width: 95%;
  margin-bottom: 25px;

  .answer-heading {
    overflow-wrap: break-word;
  }
}

#user-badge-type-container {
  .ant-select-selection.ant-select-selection--single {
    padding-top: 5px;
    padding-bottom: 5px;
    height: 45px;
  }

  .ant-select-arrow .ant-select-arrow-icon svg {
    color: #000000;
  }

  .ant-select-dropdown-menu {
    max-height: 280px;
  }
}

.user-badge-wrapper {
  align-items: center;

  .user-badge-image {
    width: 70px;
    height: 30px;
  }

  span {
    margin-left: 5px;
  }
}

.user-badges-img {
  width: auto;
  height: 20px;
  margin: 5px 5px;
}

.chat-user-badge {
  width: auto;
  height: 15px;
  margin: 5px 5px;
}

.form-download-icon {
  height: 40px;
  width: 40px;
}

.action-buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.username-label {
  padding-left: 5px;
}

.fa-usericon {
  color: #bfbfbf;
}

.fillform-button {
  margin-top: 5px;
  margin-bottom: 5px;
}

.document-button {
  margin-bottom: 5px;
}

.reply-button {
  margin-bottom: 5px;
}

.total-qt-container {
  display: flex;
  flex-direction: column;
}

.insurance-wrapper {
  margin-bottom: 20px;
}

.insurance-image {
  border: 1px solid #FF4D00;
  margin-bottom: 15px;
  border-radius: 10px;
  max-width: 95%;
  min-width: 100px;
  min-height: 100px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.barc10-container {
  display: flex;
  flex-direction: column;
}

.barc10-row-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.barc10-row-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-top: 2px;
}

.wellnessplan-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hide-peers-column {
  display: none;
}

.beta-badge {
  border: 1px solid #FF4D00;
  background-color: #FF4D00;
  color: #fff;
  font-size: 10px;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;
  height: 15px;
}

.task-icon {
  height: 25px;
  width: 25px;
}

.edit-icon-container {
  display: flex;
  justify-content: flex-end;
}

.task-label {
  font-size: 18px;
  font-weight: 600;
}

.task-root-container {
  padding: 0px 30px;
}

.task-container {
  border: 1px solid #838383;
  border-radius: 5px;
  padding: 15px;
  margin: 15px 0px;

}

.task-subcontainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.task-primary-text {
  color: #666666;
}

.task-primary-text-error {
  color: red;
}

.task-input {
  width: 100%;
  border: none;
}

.task-input:focus {
  outline: none !important;
  border: none;
}

.task-items {
  display: flex;
  align-items: center;
}

.add-task-spin-container {
  width: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-tasks {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  margin-bottom: 15px;
}

.dropdown-arrow {
  height: 13px;
}

.other-dms-not-found {
  text-align: center;
  margin-top: 50px;
}

.completed-tasks-container {
  margin: 50px 0px 100px 0px;
}

.tasks-loader {
  display: flex;
  justify-content: center;
  height: 100px;
}

.notification-container {
  background-color: white;
  border: 1px solid rgb(251, 144, 72);
  border-radius: 7px;
  margin: 0px 8px 15px 8px;
}

.notification-subcontainer {
  padding: 10px 10px 0px 10px;
}

.notification-text-primary {
  font-size: 20px;
  font-weight: 500;
}

.notification-text-secondary {
  font-size: 16px;
  font-weight: 500;
}

.notification-text-datetime {
  font-size: 14px;
  font-weight: 500;
  color: #838383;
}

.notification-count {
  height: 1.6em;
  min-width: 1.6em;
  padding: 0.2em 0.2em 0 0.2em;
  margin: -10px 0 -10px 10px;
  border-radius: 0.8em;
  background-color: red;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.05em solid white;
  font-family: "Conv_AvenirNextLTPro-Bold";

  &:hover {
    text-decoration: none !important;
  }
}

.no-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 18px;
}

.notification-section {
  display: flex;
  justify-content: 'center';
  align-items: 'flex-start';
  height: 100vh;
  border-right: 1px solid lightgrey;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 120px;
}

.notification-label {
  margin-left: 8px;
  font-size: 20px;
  font-weight: bold;
}

.notification-label-secondary {
  margin-right: 8px;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
}

.notification-date-label {
  margin-left: 8px;
  font-weight: 600;
  font-size: 16px;
}

.wrap-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;


  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.dm-filters-row-one {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .selected-territory-wrapper,
  .selected-user-status-wrapper {
    .ant-select-selection--single {
      border: 2px solid $orange;
    }
  }

  .ant-select-selection-selected-value {
    color: #ff4d00;
  }
}

.dm-filters-row-two {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.selected-admin-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;

  .close-icon {
    color: $orange;
    margin-left: 5px;
    height: 100%;
  }
}

.everyone-label,
.filter-label {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  color: $orange;
  font-size: 14px;

  &.selected {
    border: 2px solid $orange;
  }
}

.last-message {
  color: $gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .icon {
    width: 15px;
    height: 15px;
  }
}

.dm-section-list-container {
  .dm-list-date-section {
    margin-bottom: 30px;
  }

  .date-title {
    margin-left: 15px;
    font-size: 18px;
    color: $orange;
    border-bottom: 1px solid $orange;
  }
}

.text-trim {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Feed styling starts

.feed-container {
  border: solid;
  border-color: #fff;
  border-width: 1px;
  margin: 10px 10px 15px 10px;
  box-shadow: 0px 5px 5px 0px rgba(174, 174, 174, 0.25);
  background-color: #fff;
  border-radius: 5px;
}

.feed-container-deleted {
  border: solid;
  border-color: #FF4D00;
  border-width: 2px;
  margin: 10px 10px 15px 10px;
}

.feed-headline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #000000;
  font-size: 18px;
  #username-container {
    font-family: Roboto;
		font-size: 16px;
		font-weight: 700;
		line-height: 15px;
		letter-spacing: 0px;
		text-align: left;
  }
}

.feed-actions {
  height: 25px;
}

.feed-actions:hover {
  cursor: pointer;
}

.feed-like-actions {
  height: 24px;
}

.feed-like-actions:hover {
  cursor: pointer;
}

.feed-top-right-action {
  margin-top: -3px;
  cursor: pointer;
  height: 25px;
}

.feed-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.feed-action-count {
  padding-top: 5px;
  padding-left: 5px;
  color: #000000;
}

.feed-latest-comment {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

// Feed styling ends

.post-tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.post-tab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-left-tab {
  border: 1px solid rgb(251, 144, 72);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
  cursor: pointer;
}

.post-center-tab {
  border: 1px solid rgb(251, 144, 72);
  border-left: none;
  border-right: none;
  height: 100%;
  cursor: pointer;
}

.post-right-tab {
  border: 1px solid rgb(251, 144, 72);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  cursor: pointer;
}

.post-tab-active {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(251, 144, 72);
  color: #fff;
  cursor: pointer;
}

.add-post-button:hover,
.add-post-button:disabled,
.add-post-button:disabled:hover,
.add-post-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(251, 144, 72);
  color: #fff;
  height: 33px;
  border-radius: 33px;
  cursor: pointer;
  border: none;
  width: 90%;
}

.add-post-button:disabled:hover,
.add-post-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.drag-and-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  border: 1px dashed grey;
  border-radius: 10px;
}

.create-post-container {
  color: rgb(251, 144, 72);
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  border: 1px solid rgb(251, 144, 72);
  border-radius: 50px;
}

.create-post {
  background-color: #fb9048;
  cursor: pointer;
  padding: 5px 12px 5px 12px;
  border: 1px solid #fb9048;
  border-radius: 18px;
  color: #fff;
}

.load-more {
  cursor: pointer;
  background-color: rgb(251, 144, 72);
  color: white;
  padding: 7px 25px 5px 25px;
  border-radius: 50px;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.feed-comment-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}

.single-comment-container {
  margin-bottom: 20px;
}

.edit-form-icon {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.edit-reward-icon {
  height: 12px;
  width: 12px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.radio-section-wrapper {
  .ant-radio-wrapper {
    display: flex;
    align-items: center;

    span.ant-radio+* {
      white-space: normal;
    }
  }
}

.edit-form-warning {
  color: $red;
  font-size: 16px;
  font-weight: 800;
}

.form-panel-wrapper>.ant-collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-collapse-extra {
    width: 10%;
    float: none;
  }
}

.chat-icons-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  margin-right: 15px;

  &.active {
    width: 18%;
  }

  &.inactive {
    width: 15%;
  }

  &.forum-chat-icons {
    width: 8%;
  }
}

.image-upload-img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.gif-upload-img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.feed-user-badge {
  height: 14px;
  margin: 0px 5px 0px 7px;
}

.form-modal-title {
  margin-right: 20px
}

.feed-image-file-container {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.feed-selected-image {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

.feed-image-discard {
  text-decoration: underline;
  cursor: pointer;
}

.h_iframe iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.myCaseLoadInfo {
  padding: 4px 15px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  text-decoration: none;
  color: black;
}

.myCaseLoadInfo :hover {
  text-decoration: none;
  color: black;
}

.embedded {
  flex: 0 0 33.333333%;
  border: 1px solid #ccc;
  border-radius: 16px;
  padding: 5px;
  position: relative;

  &.column {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;

    .embedded-widget {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 90%;
      flex: 8;
    }
  }
}

.iframe-modal {
  height: 100vh;
  margin: 0;
  top: 0;
}

.iframe-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.iframe-loading-text {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.pivot-container {
  overflow-y: scroll !important;
}

.onboarding-status-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
}

.onboarding-status-icon {
  height: 25px;
  margin-right: 15px;
}

.onboarding-label {
  margin-top: 20px;
}

.onboarding-container {
  padding: 10px 30px;
}

.reward-primary-label {
  font-size: 18px;
  font-weight: bold;
  padding-top: 6px;
}

.reward-modal-primary-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.reward-note-textarea {
  border: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  margin-top: 15px;
  padding: 10px;
}

.reward-note {
  background-color: #fff;
  padding: 10px;
}

.reward-note-textarea:focus {
  outline: none !important;
}

.stats-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.stats-loading-text {
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 20px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

.stats-user-badge {
  height: 20px;
  margin-top: 10px;
}

.form-confirm-modal {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.user-form-action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65px;
}

.form-pending-wizard {
  border: 3px solid $orange;
  border-radius: 5px;
  background: #FFFBE9;
  position: fixed;
  width: 350px;
  height: 80px;
  right: 15px;
  bottom: 15px;
  z-index: 99;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  .user-info-inner {
    width: 80%;
  }

  .ant-progress .ant-progress-inner {
    width: 60px !important;
    height: 60px !important;
    font-size: 15px !important;
  }
}

.caseload-container {
  margin-left: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  display: flex;
  flex-direction: row
}

.user-additional-details-container {
  border: 1px solid #838383;

  .additional-details-header {
    justify-content: space-between;
    display: flex;
    padding: 20px;
    align-items: center;

    .header-title {
      color: #F98654;
      font-size: 20px;
    }

    img {
      width: 15px;
      height: 10px;
    }
  }

  .user-details-wrapper {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    padding-bottom: 25px;

    &.row,
    .row-wrapper {
      display: flex;
      flex: 1;
    }

    &.bottom-border,
    &.border {
      border-bottom: 1px solid #ECECEC;
    }

    .user-details-wrapper-inner {
      flex: 1;

      .name-wrapper {
        align-items: center;
        display: flex;
        padding: 5px;
      }

      .support-description-input {
        width: 75%;
        border: 1px solid #ECECEC;
        border-radius: 2px;
        padding: 5px;
        font-size: 14px;
      }

      #user-preferred-pronouns-container,
      #user-gender-container,
      #user-modality-container,
      #user-support-type-container,
      #user-availability-time-container,
      #user-availability-type-container {
        display: flex;
        position: relative;
      }

      .modality-active {
        color: #3FBF62
      }

      .modality-inactive {
        color: #EC4E2B
      }
    }
  }
}

.reward-status-label {
  margin-left: 10px;
  font-weight: bold;
}

.reward-reload-label {
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
}

.reward-reload-icon {
  font-size: 21px;
}

#reply-message {
  position: relative;
  min-width: calc(20rem - 10px);
  max-width: fit-content;
  margin-right: 10px;

  .reply-border {
    content: "";
    position: absolute;
    left: -2px;
    top: 0;
    bottom: 0;
    width: 10px;
    border-radius: 5px 0 0 5px;
    z-index: -9;
  }
}

#reply-message-box {

  #reply-message-container {
    padding: 10px;

    #display-data {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      padding: 10px 0;
      flex-direction: column;
    }

    .chatText {
      margin-left: 20px;
    }

    .username {
      cursor: pointer;
    }

    .msgcontainer {
      max-height: 90px;
      margin-top: 5px;

      .img-message {
        max-height: 80px;
        width: auto;
      }

      p {
        max-height: 80px;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .image-container {
      min-width: fit-content;
    }
  }
}

.input-container {
  #reply-message-box {
    padding-left: 15px;
  }
}

#reply-close-button {
  height: 10px;
  width: 10px;
  margin-right: 15px;
  margin-top: -10px;
}

.reply-username-close-button-container {
  margin-left: 20px;
  height: 25px;
  justify-content: space-between;
}

.post-radio {
  font-size: 14px;
}

.row-container {
  margin-top: 20px;
}

.primary-font {
  font-family: "Roboto";
}

.left-side-tag-filter {
  margin-top: 10px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 73.33px;
  line-height: 18px;
  height: 30px;
  padding: 0px;
  #content {
    display: flex;
    padding: 4px 9px;
    justify-content: center;
    align-items: center;
    gap: 2.444px;
  }
}

.ant-carousel .slick-dots li button {
  background: rgb(249, 134, 84);
  opacity: 0.4;
  width: 6px;
  height: 6px;
  border-radius: 100%;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: rgb(249, 134, 84);
  width: 7px;
  height: 7px;
  border-radius: 100%;
}

.feed-time {
  color: #747474;
  margin-left: 20px;
  font-size: 14px;
}

.comment-sender {
  margin-top: -3px;
  font-size: 18px;
  margin-left: -3px;
}

.feed-comment-action-dropdown {
  padding: 5px;
  cursor: pointer;
  align-items: start;
  font-size: 15px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
 
.image-container-create-post {
  position: relative;
  max-width: 150px;
  // overflow: hidden;
  border: 1px solid #979797;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px;
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 15px;
  color: #fff;
  background-color: #333;
  padding: 5px;
  border-radius: 50%;
}

.create-post-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.forum-select-container > .ant-select-selection {
  border: none !important;
  max-width: fit-content;
}

.forum-select-container > .ant-select-selection .ant-select-arrow {
  color: rgb(251, 144, 72);
}

.link-url-container {
  margin: 10px;
  margin-bottom: 0;
  font-weight: bold; 
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.21) inset;
  border: 1px ridge;
  cursor: pointer;
}

.link-url-text {
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.link-preview-data-container {
  margin: 10px;
  margin-bottom: 0;
  border-width: inherit;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.21) inset;
  border: 1px ridge;
  border-radius: 5px;
}

.flag-needs-icon {
  align-self: center;
  height: 30px;
  margin-right: 10px;
  margin-top: -5px;
}

.logout-menu-item {
  margin: 12px 10px !important;

  span {
    color: #e64821;
    border: solid;
    border-radius: 10px;
    padding: 9px 30px;
    border-width: thin;
    font-size: 18px;
  }
}

.notification-menu {
  :hover {
    text-decoration: underline;
  }
}

.right-nav-menu {
  .ant-menu-item {
    a {
      color: #0b0b0b !important;
    }
    &:hover {
      color: #0b0b0b !important;
    }
    :hover {
      text-decoration: none;
    }
  }
  background-color: white !important;
}

#right-nav-dropdown {
  ul {
    padding: 8px 0 10px 0;
  }
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#comment-counts-container {
  display: flex;
  justify-content: space-between;
  margin-left: 33px;
  margin-right: 197px;
}

#comment-list-container {
  margin-bottom: 50px;
}

.word-wrap {
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#select-feed-img {
  height: 255px;
  margin-bottom: 18px;
}

#upload-image-text {
  display: flex;
  justify-content: center;
}

#user-avatar-container {
  display: flex;
  align-items: center;
  gap: 10px;
  #profileImage {
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background: linear-gradient(90deg, #FDA651 0%, #E47B3B 100%);
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: white;
    p {
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }
  }
  #username-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 16;
  }
}

.feed-drag-and-drop-text {
  margin-left: 10px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.drag-and-drop-placeholder {
  position: absolute;
  margin-bottom: -140px;
}

#feed-comment-label {
  display: flex;
  justify-content: flex-end;
  color: red;
}

.feed-capsule-label {
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  height: 22px;
  padding: 0px;
  border-radius: 60px;
  color: white;
  margin-right: 0;
}

#feed-tags-container {
  margin: 9px 20px 0 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

#feed-category {
  display: flex;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  gap: 2.444px;
  height: 22px;
  #icon {
    height: 22px;
    padding-top: 4px;
  }
}

#feed-tag {
  display: flex;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
}

#create-post-feed-category {
  display: flex;
  padding: 5.026px;
  justify-content: center;
  align-items: center;
  gap: 2.513px;
  height: 35px;
}

#create-post-feed-tag {
  border-radius: 60px;
  margin-top: 7px;
  cursor: pointer;
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  height: 34px;
}

.feed-input-container-actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 8%;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}

#modal-carousel-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  #next-btn-container {
    display: flex;
    justify-content: flex-end;
  }
  .carousel-btns {
    border: 2px solid black;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 33px;
    color: black;
  }
}

.popover-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
