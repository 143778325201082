@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

$green: #86BB71;
$blue: #94C2ED;
$sender-border:#F7971E;
$sender-bg:#FFFBE9;
$receiver-border:#FF512F;
$receiver-bg: #FFFEF9;
$orange: #FF4D00;
$gray: #92959E;

.sms-inbox-container{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border-bottom: 2px solid #E3E3E3;
    overflow: hidden;

    .people-list {
      width: 25%;
      height: calc(100vh - 75px);
      border-right: 2px solid #E3E3E3;
      display: flex;
      flex-direction: column;

      .everyone-label, .filter-label{
        border: 1px solid #d9d9d9;
        cursor: pointer;
        border-radius: 4px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 3.5px;
        padding-bottom: 3.5px;
        color: $orange;
        font-size: 14px;
        &.selected{
          border: 2px solid $orange;
        }
      }

      .bulk-send-sms-label{
        margin-bottom: 10px;
        align-items: center;
        height: 45px;
        cursor: pointer;
        border-radius: 4px;
        color: $orange;
        border: 2px solid #d9d9d9;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        font-size: 14px;
        &:hover{
          border: 2px solid $orange;
        }
        &.main{
          width: 60%;
          margin-left: 5%;
        }
        &.inner{
          width: 30%;
          margin-left: 10px;
          margin-right: 5%;
        }
      }

      .selected-territory-wrapper, .selected-user-status-wrapper{
        .ant-select-selection--single {
          border: 2px solid $orange;
        }
      }

      .ant-select-selection-selected-value {
        color: #ff4d00;
      }

      .inbox-update-time{
        margin-top:10px;
        margin-left: 10px;
      }

      .search {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
      }
      
      .inbox-input{
        border-radius: 10px;
        color: #fff;
        width: 90%;
        font-size: 14px;
        .ant-input{
          height: 50px;
          border-radius: 10px;
          border: 2px solid #6a6c75;
          &:hover, &:active{
            border-color: #6a6c75;
          }
        }
      }
      
      .list {
        padding-top: 20px;
        width: 100%;
        list-style: none;

        #inbox-users-list-container{
          height: 100%;
          overflow-y: scroll;
          overflow-x: hidden;
        }

        h5{
          text-align: center;
        }

        .date-title{
          margin-left: 10px;
          font-size: 18px;
          border-bottom: 1px solid $orange;
        }

        .users-list-date-section{
          margin-bottom: 30px;
        }

        li {
            cursor: pointer;
            border-radius: 10px;
            margin-bottom: 10px;
            padding-left: 15px;
            list-style: none;
            height: 80px;
        }

        li:hover{
            background: $orange;
            color: #fff;
            opacity: 0.7;
            .last-message{
                color: #fff;
            }
        }

        .selected-user-wrapper{
            background: $orange;
            color: #fff;
            .last-message{
                color: #fff;
            }
        }

      }
      
      .about {
        float: left;
        margin-top: 8px;
        padding-left: 10px;
        width: 95%;
        .name{
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      
      .last-message {
        color: $gray;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      
    }
    
    .sms-inbox-chat {
      width: 75%;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 75px);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: #434651;
      background: #FFFFFF;
      justify-content: center;

      .sms-inbox-header {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 2px solid #E3E3E3;
        display: flex;
        flex: 0 0 auto;
        
        .chat-about {
          float: left;
          padding-left: 10px;
          margin-top: 6px;
        }
        
        .chat-with {
          font-weight: bold;
          font-size: 16px;
          .sender-username{
            color: #0000ff;
            cursor: pointer;
          }
        }
        
        .chat-num-messages {
          color: $gray;
        }
        
        .fa-star {
          float: right;
          color: #D8DADF;
          font-size: 20px;
          margin-top: 12px;
        }
      }
      
      .sms-inbox-history {
        padding: 30px 30px 20px;
        border-bottom: 2px solid white;
        overflow-y: scroll;
        overflow-x: hidden;
        flex: 1 1 auto;

        .message-date {
          margin: 0 auto;
          text-align: center;
    
          span {
            padding: 3px 5px;
            font-size: 12px;
            border-radius: 5px;
            background: #fdd5b4;
          }
        }

        .left-side, .clearfix{
          list-style: none;
        }
  
        .message-data {
          margin-bottom: 15px;
        }
        
        .message-data-time {
          color: lighten($gray, 8%);
          padding-left: 6px;
        }
        
        .message {      
          color: white;
          padding: 18px 20px;
          line-height: 26px;
          font-size: 16px;
          border-radius: 7px;
          margin-bottom: 30px;
          width: 50%;
          position: relative;
          
          &:after {
            bottom: 100%;
            left: 7%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: $receiver-border;
            border-width: 10px;
            margin-left: -10px;
          }
        }
        
        .my-message {
          background: $receiver-bg;
          border: 1px solid;
          border-color: $receiver-border;
          color: #000000;
        }
        
        .other-message {
          background: $sender-bg;
          border: 1px solid;
          border-color: $sender-border;
          color: #000000;
          overflow-wrap: break-word;
          
          &:after {
            border-bottom-color: $sender-border;
            left: 93%;
          }
        }
        
      }
      
      .sms-inbox-message {
        padding: 30px;
        border-top: 2px solid #E3E3E3;
        align-items: center;
        display: flex;
        flex: 0 0 auto;

        textarea {
          width: 95%;
          border: 2px solid #E3E3E3;
          padding: 10px 20px;
          font: 14px/22px "Lato", Arial, sans-serif;
          margin-bottom: 10px;
          border-radius: 5px;
          resize: none;

        }
        
        .fa-file-o, .fa-file-image-o {
          font-size: 16px;
          color: gray;
          cursor: pointer;
          
        }
        
        button {
          float: right;
          color: $blue;
          font-size: 16px;
          text-transform: uppercase;
          border: none;
          cursor: pointer;
          font-weight: bold;
          background: #F2F5F8;
          
          &:hover {
            color: darken($blue, 7%);
          }
        }
      }
    }

    .sms-inbox-no-data{
      text-align: center;
    }
    
    .online, .offline, .me {
        margin-right: 3px;
        font-size: 10px;
      }
      
    .online {
      color: $green;
    }
      
    .offline {
      color: $orange;
    }
    
    .me {
      color: $blue;
    }
    
    .align-left {
      text-align: left;
    }
    
    .align-right {
      text-align: right;
    }
    
    .float-right {
      float: right;
    }
    
    .clearfix:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }

    .loading-spinner{
      justify-content: center;
      text-align: center;
    }
}